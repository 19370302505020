import { DevicesActionTypes } from "./devices.types";
import {
  REMOVE_PROFILE_SUCCESS,
  RENAME_PROFILE_SUCCESS,
} from "../profiles/profiles.types";
import {
  EDIT_DEVICE_SUCCESS,
  FETCH_LOGICAL_DEVICES,
  FETCH_LOGICAL_DEVICES_FAILURE,
  FETCH_LOGICAL_DEVICES_SUCCESS,
  logicalDevicesReducer,
  MERGE_DEVICE_SUCCESS,
  SAVE_DEVICE_SUCCESS,
  UNMERGE_DEVICE_SUCCESS,
  REMOVE_DEVICE_SUCCESS,
  EDIT_ROAMING_DEVICE_SUCCESS,
  REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS,
  FETCH_ROAMING_LIMIT_SUCCESS,
  LogicalDevicesReducerState,
} from "./logicalDevices";
import {
  FETCH_DISCOVERED,
  FETCH_DISCOVERED_SUCCESS,
  FETCH_DISCOVERED_FAILURE,
  discoveredDevicesReducer,
  DiscoveredDevicesReducerState,
} from "./discovered";
import {
  FETCH_REQUESTS_NAME,
  FETCH_REQUESTS_NAME_FAILURE,
  FETCH_REQUESTS_NAME_SUCCESS,
  RequestsWithNameReducerState,
  requestsWithNameReducer,
} from "./requestsWithName";
import {
  FETCH_LINES,
  FETCH_LINES_FAILURE,
  FETCH_LINES_SUCCESS,
  LineDevicesReducerState,
  linesDevicesReducer,
} from "./lines";
import {
  FETCH_INFECTED,
  FETCH_INFECTED_FAILURE,
  FETCH_INFECTED_SUCCESS,
  infectedDevicesReducer,
  InfectedDevicesReducerState,
} from "./infectedDevices";

export interface DevicesState {
  logical: LogicalDevicesReducerState;
  limit?: number;
  roamingDevicesLimit?: number;
  discovered: DiscoveredDevicesReducerState;
  infected: InfectedDevicesReducerState;
  withName: RequestsWithNameReducerState;
  lines: LineDevicesReducerState;
}

export const initialState: DevicesState = {
  logical: logicalDevicesReducer(undefined, {}),
  discovered: discoveredDevicesReducer(undefined, {}),
  infected: infectedDevicesReducer(undefined, {}),
  withName: requestsWithNameReducer(undefined, {}),
  lines: linesDevicesReducer(undefined, {}),
};

export default (
    state = initialState,
    action: DevicesActionTypes
): DevicesState => {
  switch (action.type) {
    case FETCH_DISCOVERED:
    case FETCH_DISCOVERED_FAILURE:
    case FETCH_DISCOVERED_SUCCESS: {
      return {
        ...state,
        discovered: discoveredDevicesReducer(state.discovered, action),
      };
    }
    case FETCH_INFECTED:
    case FETCH_INFECTED_FAILURE:
    case FETCH_INFECTED_SUCCESS: {
      return {
        ...state,
        infected: infectedDevicesReducer(state.infected, action),
      };
    }
    case FETCH_REQUESTS_NAME:
    case FETCH_REQUESTS_NAME_FAILURE:
    case FETCH_REQUESTS_NAME_SUCCESS: {
      return {
        ...state,
        withName: requestsWithNameReducer(state.withName, action),
      };
    }
    case FETCH_LINES:
    case FETCH_LINES_FAILURE:
    case FETCH_LINES_SUCCESS: {
      return {
        ...state,
        lines: linesDevicesReducer(state.lines, action),
      };
    }
    case FETCH_LOGICAL_DEVICES_SUCCESS: {
      return {
        ...state,
        limit: action.payload.limit,
        logical: logicalDevicesReducer(state.logical, action),
      };
    }
    case FETCH_ROAMING_LIMIT_SUCCESS: {
      return {
        ...state,
        roamingDevicesLimit: action.payload,
      };
    }
    case FETCH_LOGICAL_DEVICES:
    case FETCH_LOGICAL_DEVICES_FAILURE:
    case SAVE_DEVICE_SUCCESS:
    case EDIT_DEVICE_SUCCESS:
    case MERGE_DEVICE_SUCCESS:
    case UNMERGE_DEVICE_SUCCESS:
    case REMOVE_DEVICE_SUCCESS:
    case EDIT_ROAMING_DEVICE_SUCCESS:
    case REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS:
    case REMOVE_PROFILE_SUCCESS:
    case RENAME_PROFILE_SUCCESS: {
      return {
        ...state,
        logical: logicalDevicesReducer(state.logical, action),
      };
    }
    default: {
      return state;
    }
  }
};
