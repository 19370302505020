import { DiscoveredDevice } from "@sportal/api";

export const FETCH_DISCOVERED = "[DEVICES] FETCH_DISCOVERED";
export const FETCH_DISCOVERED_SUCCESS = "[DEVICES] FETCH_DISCOVERED_SUCCESS";
export const FETCH_DISCOVERED_FAILURE = "[DEVICES] FETCH_DISCOVERED_FAILURE";

interface FetchDiscoveredRequestAction {
  type: typeof FETCH_DISCOVERED;
}

interface FetchDiscoveredSuccessAction {
  type: typeof FETCH_DISCOVERED_SUCCESS;
  payload: DiscoveredDevice[];
}

interface FetchDiscoveredFailureAction {
  type: typeof FETCH_DISCOVERED_FAILURE;
  payload: object & { code: string | number };
}

export type DiscoveredActionTypes =
  | FetchDiscoveredRequestAction
  | FetchDiscoveredSuccessAction
  | FetchDiscoveredFailureAction;
