import { RequestWithName } from "@sportal/api";

export const FETCH_REQUESTS_NAME = "[DEVICES] FETCH_REQUESTS_NAME";
export const FETCH_REQUESTS_NAME_SUCCESS =
  "[DEVICES] FETCH_REQUESTS_NAME_SUCCESS";
export const FETCH_REQUESTS_NAME_FAILURE =
  "[DEVICES] FETCH_REQUESTS_NAME_FAILURE";

interface FetchRequestsWithNameAction {
  type: typeof FETCH_REQUESTS_NAME;
}

interface FetchRequestsWithNameSuccessAction {
  type: typeof FETCH_REQUESTS_NAME_SUCCESS;
  payload: RequestWithName[];
}

interface FetchRequestsWithNameFailureAction {
  type: typeof FETCH_REQUESTS_NAME_FAILURE;
  payload: object & { code: string | number };
}

export type RequestsWithNameActionTypes =
  | FetchRequestsWithNameAction
  | FetchRequestsWithNameSuccessAction
  | FetchRequestsWithNameFailureAction;
