import _ from "lodash";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import {LogicalDeviceDetailsTypes} from "@sportal/api";

import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog,
  ModalFormControl
} from "../../../modal";
import {
  isDeviceNameBusy,
  isDeviceNameValid
} from "../../../../helpers/validation.helper";

import {
  DeviceNameInput,
  IdentifiersStub,
  ProfileSelect
} from "../../commonComponents";
import {
  isAddressInList
} from "../../../../helpers/devices.helpers";

const CLASS_NAME = "assign-device-modal";

class AssignDeviceDialog extends Component {
  constructor(props) {
    super(props);

    const deviceName = this.getDeviceName();
    const { validateImmediately } = props;
    const firstNotBlockedProfile = _.find(
      props.profiles,
      ({ isDisabled }) => !isDisabled
    ).value;

    this.state = {
      name: deviceName,
      errorName: validateImmediately
          ? this.getNameInitialError(deviceName)
          : "",
      profile: firstNotBlockedProfile
    };
  }

  getDeviceName() {
    const { device, requested } = this.props;

    if (device.name) return device.name;
    if (device.type === LogicalDeviceDetailsTypes.Line)
      return device.identifiers[0];

    const deviceRequested = _.find(requested, (d) =>
        isAddressInList(device.identifiers, d.id)
    );
    return deviceRequested ? deviceRequested.name : "";
  }

  getNameInitialError(name) {
    const { devices } = this.props;

    if (!name) return "";

    const invalidError = isDeviceNameValid(name);
    const isNameBusy = isDeviceNameBusy(devices, name);
    const error = invalidError
        ? invalidError
        : isNameBusy
            ? "device_name_already_exist"
            : "";

    return error && this.props.intl.formatMessage({ id: error });
  }

  render() {
    const { modal, device } = this.props;

    return (
      <ModalDialog
        className={CLASS_NAME}
        submitHandler={this.onSubmit}
        rejectHandler={() => modal.reject()}
        disabled={this.isSubmitDisabled()}
      >
        <CommonModalHeader>
          <FormattedMessage id={"assign_device"} />
        </CommonModalHeader>
        <ModalDialog.Body>
          <ModalFormControl>
            <IdentifiersStub
              className={CLASS_NAME}
              value={device.identifiers.join(", ")}
            />
          </ModalFormControl>
          <ModalFormControl>
            <DeviceNameInput
              name={this.state.name}
              error={this.state.errorName}
              devices={this.props.devices}
              handleChange={(name, error) => this.handleNameChange(name, error)}
              label={<FormattedMessage id={"device_name"} />}
              autoFocus
            />
          </ModalFormControl>
          <ModalFormControl>
            <ProfileSelect
              profiles={this.props.profiles}
              profile={this.state.profile}
              className={CLASS_NAME}
              handleChange={selected => this.handleProfileChange(selected)}
            />
          </ModalFormControl>
        </ModalDialog.Body>
        <CommonModalFooter submitMessage={<FormattedMessage id="assign" />} />
      </ModalDialog>
    );
  }

  handleProfileChange(profile) {
    if (profile === this.state.profile) return;

    this.setState({ profile });
  }

  handleNameChange(name, error) {
    if (name === this.state.name) return;

    this.setError("errorName", error);
    this.setState({ name });
  }

  setError(field, error) {
    if (error) {
      this.setState({ [field]: this.props.intl.formatMessage({ id: error }) });
    } else {
      this.setState({ [field]: "" });
    }
  }

  isSubmitDisabled() {
    const { errorName, name } = this.state;

    return !name || errorName;
  }

  onSubmit = () => {
    if (this.isSubmitDisabled()) return;

    const { modal, device } = this.props;
    const { name, profile } = this.state;

    return modal.resolve({
      identifiers: device.identifiers,
      name,
      profile
    });
  };
}

AssignDeviceDialog.propTypes = {
  intl: PropTypes.object,
  modal: PropTypes.object,
  device: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  devices: PropTypes.object,
  profiles: PropTypes.arrayOf(PropTypes.object),
  requested: PropTypes.arrayOf(PropTypes.object)
};

export default injectIntl(AssignDeviceDialog);
