import React, { Fragment, useMemo } from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Mobile,
  Tablet,
  Desktop,
  TabletMobile
} from "../../../hocs/Responsive";
import { ClickAbsorber } from "../../../components/clickAbsorber/ClickAbsorber";
import { Table } from "../../../components/table/Table";
import { useRoamingDevices } from "../../spson/roamingTables/useRoamingDevices";
import { ProtectionStatus } from "../../spson/roamingTables/ProtectionStatus";
import { timeSort } from "../../../components/table/helpers/sorting.helper";
import {
  RoamingRowSubComponent,
  RoamingRowSubComponentMobile
} from "../../spson/roamingTables/RoamingRowSubComponent";
import {
  RoamingDeviceNameCard,
  RoamingDeviceNameMobileCard
} from "../../spson/roamingTables/RoamingDeviceNameCard";
import { FormattedProfileName } from "../../../components/formattedProfileName/FormattedProfileName";
import { ProfileGroups } from "../../../store/profiles/fixedProfiles";
import { RoamingDevicesDropdownActions } from "./RoamingDevicesDropdownActions";
import { ellipsifyUUID } from "../../../helpers/devices.helpers";
import { getDefaultProfile } from "../../../store/profiles/profiles.selectors";
import { RoamingCounter } from "../../../components/roamingCounter/RoamingCounter";

const COMMON_COLUMNS = [
  {
    Header: <FormattedMessage id={"protection_status"} />,
    accessor: "protectionStatus",
    cellClassName: "table__cell--center",
    headerClassName: "table__cell--center",
    Cell: ({ row }) => (
      <ProtectionStatus status={row.original.protectionStatus} />
    )
  },
  {
    Header: <FormattedMessage id={"last_seen"} />,
    accessor: "lastSeen",
    sortType: timeSort,
    cellClassName: "color-secondary",
    Cell: ({
      row: {
        original: { lastSeen }
      }
    }) => <div>{lastSeen || "—"}</div>
  },
  {
    Header: <FormattedMessage id={"actions"} />,
    id: "actions",
    sortable: false,
    headerClassName: "table__cell--small-cell",
    cellClassName: "table__cell--center table__cell--overflow",
    Cell: ({
      row: {
        original: { identifier, fullName, name }
      }
    }) => (
      <ClickAbsorber>
        <RoamingDevicesDropdownActions
          identifier={identifier}
          fullName={fullName}
          name={name}
        />
      </ClickAbsorber>
    )
  }
];

const COLUMNS_MOBILE = [
  {
    accessor: "name",
    SortingLabel: <FormattedMessage id={"device_name"} />,
    Cell: ({
      row: {
        isExpanded,
        original: { identifier, name, protectionStatus, lastSeen }
      }
    }) => (
      <RoamingDeviceNameMobileCard
        isExpanded={isExpanded}
        name={name}
        lastSeen={lastSeen}
        protectionStatus={protectionStatus}
        aside={
          <RoamingDevicesDropdownActions
            identifier={identifier}
            fullName={name || ellipsifyUUID(identifier)}
            name={name}
          />
        }
      />
    )
  },
  {
    SortingLabel: <FormattedMessage id={"protection_status"} />,
    accessor: "protectionStatus"
  },
  {
    accessor: "lastSeen",
    sortType: timeSort,
    SortingLabel: <FormattedMessage id={"last_seen"} />
  }
];

const COLUMNS_TABLET = [
  {
    Header: <FormattedMessage id={"device_name"} />,
    accessor: "name",
    headerClassName: "roaming-name-column",
    SortingLabel: <FormattedMessage id={"device_name"} />,
    Cell: ({
      row: {
        isExpanded,
        original: { name, profile }
      }
    }) => (
      <div>
        <RoamingDeviceNameCard isExpanded={isExpanded} name={name} />
        <div className="device-group-substring">
          <FormattedProfileName profileName={profile} />
        </div>
      </div>
    )
  },
  ...COMMON_COLUMNS
];

const COLUMNS_DESKTOP = [
  {
    Header: <FormattedMessage id={"device_name"} />,
    accessor: "name",
    SortingLabel: <FormattedMessage id={"device_name"} />,
    Cell: ({
      row: {
        isExpanded,
        original: { name }
      }
    }) => <RoamingDeviceNameCard isExpanded={isExpanded} name={name} />
  },
  {
    Header: <FormattedMessage id={"group"} />,
    accessor: "profile",
    Cell: ({ row: { original } }) => (
      <FormattedProfileName profileName={original.profile} />
    )
  },
  ...COMMON_COLUMNS
];

const SEARCH_KEYS = [
  "appStatus",
  "fullName",
  "identifier",
  "lastSeen",
  "model",
  "name",
  "os",
  "softwareVersion"
];

const initialSort = [{ id: "lastSeen", desc: true }];

export const RoamingDevicesTable = () => {
  const data = useRoamingDevices();

  return (
    <Fragment>
      <Mobile>
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          showMobileSort
          visibleHeader={false}
          data={data}
          columns={COLUMNS_MOBILE}
          defaultSorted={initialSort}
          noDataMessageID={"no_devices_found"}
          hiddenColumns={["protectionStatus", "lastSeen"]}
          subHeader={<RoamingDevicesSubHeader />}
          renderRowSubComponent={RoamingRowSubComponentMobile}
          searchAriaLabelMessage="aria_search_for_device"
          mobileSortAside={<RoamingCounter />}
        />
      </Mobile>
      <Tablet>
        <RoamingCounter />
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          columns={COLUMNS_TABLET}
          data={data}
          defaultSorted={initialSort}
          subHeader={<RoamingDevicesSubHeader />}
          renderRowSubComponent={RoamingRowSubComponent}
          noDataMessageID={"no_devices_found"}
          searchAriaLabelMessage="aria_search_for_device"
        />
      </Tablet>
      <Desktop>
        <RoamingCounter />
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          columns={COLUMNS_DESKTOP}
          data={data}
          defaultSorted={initialSort}
          subHeader={<RoamingDevicesSubHeader />}
          renderRowSubComponent={RoamingRowSubComponent}
          noDataMessageID={"no_devices_found"}
          searchAriaLabelMessage="aria_search_for_device"
        />
      </Desktop>
    </Fragment>
  );
};

const RoamingDevicesSubHeader = () => {
  const defaultProfileId = useSelector(state => getDefaultProfile(state).id);

  return (
    <Fragment>
      <FormattedMessage id={"roaming_devices_table_description"} />{" "}
      <Link to={`settings/devices/${defaultProfileId}/roaming`}>
        <Desktop>
          <FormattedMessage id="click_here" />
        </Desktop>
        <TabletMobile>
          <FormattedMessage id="tap_here" />
        </TabletMobile>
      </Link>
    </Fragment>
  );
};
