import { useSelector } from "react-redux";
import _ from "lodash";

import { useRoamingDevicesEnhancer } from "../../../spson/roamingTables/useRoamingDevices";
import {getRoamingDevicesByProfile} from "../../../../store/devices/logicalDevices";

export const useProfileRoamingDevices = profileName => {
  const roamingDevices = useSelector(
    getRoamingDevicesByProfile(profileName),
    _.isEqual
  );

  return useRoamingDevicesEnhancer(roamingDevices);
};
