import { createSelector } from "reselect";
import { Line } from "@sportal/api";
import _ from "lodash";

import { getDevices } from "../../root.selectors";
import { LineDevicesReducerState } from "./lines.reducer";
import { RootState } from "../../root.reducer";

export const getLines = (state: RootState): LineDevicesReducerState =>
  getDevices(state).lines;

export const getLinesList = (state: RootState): Line[] => getLines(state).list;

export const getLinesMap = createSelector(
  getLinesList,
  (lines): Record<string, Line> => _.keyBy(lines, "id")
);
