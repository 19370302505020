import { DiscoveredDevice } from "@sportal/api";

import { DiscoveredDevicesReducerState } from "./discovered.reducer";
import { RootState } from "../../root.reducer";

export const getDiscoveredDevices = (
  state: RootState
): DiscoveredDevicesReducerState => state.devices.discovered;

export const getDiscoveredDevicesList = (
  state: RootState
): DiscoveredDevice[] => getDiscoveredDevices(state).list;
