import { Line } from "@sportal/api";

export const FETCH_LINES = "[DEVICES] FETCH_LINES";
export const FETCH_LINES_SUCCESS = "[DEVICES] FETCH_LINES_SUCCESS";
export const FETCH_LINES_FAILURE = "[DEVICES] FETCH_LINES_FAILURE";

interface FetchLinesRequestAction {
  type: typeof FETCH_LINES;
}

interface FetchLinesSuccessAction {
  type: typeof FETCH_LINES_SUCCESS;
  payload: Line[];
}

interface FetchLinesFailureAction {
  type: typeof FETCH_LINES_FAILURE;
  payload: object & { code: number | string };
}

export type LineActionTypes =
  | FetchLinesRequestAction
  | FetchLinesSuccessAction
  | FetchLinesFailureAction;
