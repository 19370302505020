import _ from "lodash";

import { DiscoveredDevice } from "@sportal/api";
import { LoadingState } from "../../shared/withLoadableSlice.types";
import { withLoadingState } from "../../shared";
import {
  DiscoveredActionTypes,
  FETCH_DISCOVERED,
  FETCH_DISCOVERED_FAILURE,
  FETCH_DISCOVERED_SUCCESS,
} from "./discovered.types";

export interface DiscoveredDevicesReducerState extends LoadingState {
  list: DiscoveredDevice[];
}

export const initialState: DiscoveredDevicesReducerState = {
  isLoading: false,
  success: false,
  error: null,
  list: [],
};

const discoveredReducer = (
  state = initialState,
  action: DiscoveredActionTypes
): DiscoveredDevicesReducerState => {
  switch (action.type) {
    case FETCH_DISCOVERED_SUCCESS: {
      const { payload } = action;

      const data = _.chain(payload)
        .orderBy("lastSeen", "desc")
        .uniqBy("id")
        .value();

      return {
        ...state,
        list: data,
      };
    }
    default: {
      return state;
    }
  }
};

export const discoveredDevicesReducer = withLoadingState({
  loadActionType: FETCH_DISCOVERED,
  successActionType: FETCH_DISCOVERED_SUCCESS,
  failureActionType: FETCH_DISCOVERED_FAILURE,
})(discoveredReducer);
