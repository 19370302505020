import { LoadingState } from "../../shared/withLoadableSlice.types";
import { withLoadingState } from "../../shared";
import {
  FETCH_INFECTED,
  FETCH_INFECTED_SUCCESS,
  FETCH_INFECTED_FAILURE,
  InfectedDevicesActionTypes,
} from "./infectedDevices.types";
import { InfectedDeviceServerData } from "@sportal/api";

export interface InfectedDevicesReducerState extends LoadingState {
  list: InfectedDeviceServerData;
}

export const initialState: InfectedDevicesReducerState = {
  isLoading: false,
  success: false,
  error: null,
  list: {},
};

const reducer = (
  state = initialState,
  action: InfectedDevicesActionTypes
): InfectedDevicesReducerState => {
  switch (action.type) {
    case FETCH_INFECTED_SUCCESS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const infectedDevicesReducer = withLoadingState({
  loadActionType: FETCH_INFECTED,
  successActionType: FETCH_INFECTED_SUCCESS,
  failureActionType: FETCH_INFECTED_FAILURE,
})(reducer);
