import React, { Fragment, useMemo, useRef } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import _ from "lodash";

import classnames from "classnames";
import { Mobile, NotMobile } from "../../hocs/Responsive";
import { Select } from "../select";
import { ProfilesNativeSelect } from "../profiles/ProfilesNativeSelect";

import Input from "../input/Input";
import {
  isDeviceAddressExist,
  isDeviceNameBusy,
  isDeviceNameValid,
  isMacValid,
  isUserNameValid
} from "../../helpers/validation.helper";
import ButtonLink from "../buttonLink/ButtonLink";
import { Icon } from "../icon/Icon";
import { formatProfileNameInHook } from "../profiles/FormattedProfileName";
import { useIntlTranslate } from "../../hooks";

import "./commonStyles.scss";

export const ProfileSelect = ({
  profiles,
  profile,
  handleChange,
  className
}) => {
  const translate = useIntlTranslate();

  const items = useMemo(() => {
    return profiles.map(profile => ({
      value: profile.value,
      content: formatProfileNameInHook(profile.name, translate),
      disabled: profile.isDisabled
    }));
  }, [profiles, translate]);

  return (
    <Fragment>
      {profiles.length !== 1 ? (
        <Fragment>
          <NotMobile>
            <Select
              items={items}
              selected={profile}
              onChange={selected => handleChange(selected)}
              label={translate("device_group")}
            />
          </NotMobile>
          <Mobile>
            <ProfilesNativeSelect
              id="device-group-select"
              profiles={profiles}
              selected={profile}
              onChange={handleChange}
              label="device_group"
            />
          </Mobile>
        </Fragment>
      ) : (
        <div className={`${className}__select-stub`}>
          <div className={`${className}__select-stub__label`}>
            <FormattedMessage id={"device_group"} />
          </div>
          <div className={`${className}__select-stub__text`}>
            <FormattedMessage id={profiles[0].name} />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export const IdentifiersStub = ({
  value,
  className,
  label = "device_id",
  id = "device-id"
}) => {
  return (
    <Input
      className={`${className}__identifiers-stub`}
      id={id}
      value={value}
      label={<FormattedMessage id={label} />}
      onChange={_.noop}
      disabled
    />
  );
};

export const IdentifiersStubForUnmerge = ({
  className,
  value,
  label,
  removed,
  disabled,
  onClick
}) => {
  const iconClass = removed ? "fa-undo color-primary" : "fa-times color-danger";
  const classes = classnames(
    {
      [`${className}__identifiers--disabled`]: removed
    },
    `${className}__identifiers`
  );

  return (
    <div className={classes}>
      <span className={`${className}__identifiers-title`}>
        <FormattedMessage id={label} />:{" "}
      </span>
      <span className={`${className}__identifiers-content`}>{value}</span>
      <ButtonLink disabled={disabled} onClick={onClick}>
        <Icon icon={`fas ${iconClass}`} />
      </ButtonLink>
    </div>
  );
};

export const DeviceNameInput = ({
  name,
  error,
  handleChange,
  devices,
  autoFocus = false,
  allowEmptyName = false,
  label
}) => {
  const { current: originalName } = useRef(name);
  const filteredDevices = useMemo(
    () => _.filter(devices, ({ name }) => name !== originalName),
    [devices, originalName]
  );
  const commonProps = {
    id: "device-name-input",
    errorMessage: error,
    value: name,
    label: label,
    onChange: ({ target: { value } }) =>
      onDeviceNameChange(value, handleChange, filteredDevices, allowEmptyName),
    onClear: () =>
      onDeviceNameChange("", handleChange, filteredDevices, allowEmptyName)
  };

  return (
    <Fragment>
      <Mobile>
        <Input {...commonProps} />
      </Mobile>
      <NotMobile>
        <Input {...commonProps} autoFocus={autoFocus} />
      </NotMobile>
    </Fragment>
  );
};

export const MacAddressInput = ({
  error,
  address = "",
  handleChange,
  autoFocus,
  devices,
  label
}) => {
  const commonProps = {
    id: "mac-address-input",
    errorMessage: error,
    maxLength: "17",
    autoCorrect: "off",
    value: address,
    label: label,
    onChange: ({ target: { value } }) =>
      onMacAddressChange(value, handleChange, devices),
    onClear: () => onMacAddressChange("", handleChange, devices)
  };

  return (
    <Fragment>
      <Mobile>
        <Input {...commonProps} />
      </Mobile>
      <NotMobile>
        <Input {...commonProps} autoFocus={autoFocus} />
      </NotMobile>
    </Fragment>
  );
};

export const UserFullNameInput = ({
  value,
  error,
  handleChange,
  autoFocus = false,
  allowEmptyName = false,
  label
}) => {
  const commonProps = {
    id: "user-fullname-input",
    errorMessage: error,
    value,
    label,
    onChange: ({ target: { value } }) =>
      onUserFullNameChange(value, handleChange, allowEmptyName),
    onClear: () => onUserFullNameChange("", handleChange, allowEmptyName)
  };

  return (
    <Fragment>
      <Mobile>
        <Input {...commonProps} />
      </Mobile>
      <NotMobile>
        <Input {...commonProps} autoFocus={autoFocus} />
      </NotMobile>
    </Fragment>
  );
};

const onDeviceNameChange = (name, handler, devices, allowEmptyName) => {
  const invalidError = isDeviceNameValid(name, allowEmptyName);
  const isNameBusy = isDeviceNameBusy(devices, name);
  const isEmptyString =
    allowEmptyName && name.length > 0 && name.trim().length === 0;

  const error = isEmptyString
    ? "name_required"
    : invalidError
    ? invalidError
    : isNameBusy
    ? "device_name_already_exist"
    : "";

  return handler && handler(name, error);
};
const onMacAddressChange = (value, handler, devices) => {
  const address = _.trim(value);
  const invalidError = isMacValid(address);
  const isExist = isDeviceAddressExist(devices, address);
  const error = invalidError
    ? invalidError
    : isExist
    ? "device_already_exist"
    : "";

  return handler && handler(address, error);
};
const onUserFullNameChange = (value, handler, allowEmptyName) => {
  const invalidError = isUserNameValid(value, allowEmptyName);
  const isEmptyString =
    allowEmptyName && value.length > 0 && value.trim().length === 0;

  const error = isEmptyString
    ? "name_required"
    : invalidError
    ? invalidError
    : "";

  return handler && handler(value, error);
};

export const InputStub = ({ value, label }) => (
  <Input disabled value={value} onChange={_.noop} label={label} />
);

ProfileSelect.propTypes = {
  profile: PropTypes.string,
  profiles: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
};

IdentifiersStub.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string
};

DeviceNameInput.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  devices: PropTypes.object.isRequired,
  handleChange: PropTypes.func
};

MacAddressInput.propTypes = {
  error: PropTypes.string,
  autoFocus: PropTypes.bool,
  address: PropTypes.string,
  devices: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

UserFullNameInput.propTypes = {
  error: PropTypes.string,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func
};

InputStub.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func
};
