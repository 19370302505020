import _ from "lodash";
import { createSelector } from "reselect";

import { getDevices } from "../../root.selectors";
import { RootState } from "../../root.reducer";
import {
  isRoamingType,
  SBDeviceDetails,
  SBLogicalDevice, SBLogicalDeviceWithDetails, SBRoamingDeviceDetails,
} from "../devices.types";
import { ProfileGroups } from "../../profiles/fixedProfiles";
import { LogicalDeviceDetailsTypes } from "@sportal/api";
import { LogicalDevicesReducerState } from "./logicalDevices.reducer";
import {RoamingDeviceStatus} from "./logicalDevices.types";

export const getRoamingLimit = (state: RootState): number =>
  getDevices(state).roamingDevicesLimit;

export const getLimit = (state: RootState): number => getDevices(state).limit;

export const getLogicalDevices = (state: RootState) =>
  getDevices(state).logical;

export const getLogicalDevicesEntities = (
  state: RootState
): Record<string, SBLogicalDevice> => getLogicalDevices(state).entities;

export const getDevicesDetails = (
  state: RootState
): Record<string, SBDeviceDetails> =>
  getLogicalDevices(state).detailsByIdentifier;

export const getDevicesByProfile = (profileName: string) =>
  createSelector([getLogicalDevicesEntities], (devices): SBLogicalDevice[] =>
    _.filter(devices, { profile: profileName })
  );

interface GetBlockedDevices {
  (state: RootState): SBLogicalDevice[];
}

export const getBlockedDevices: GetBlockedDevices = getDevicesByProfile(
  ProfileGroups.Blocked
);

export const getNotBlockedDevices = createSelector(
  [getLogicalDevicesEntities],
  (devices): SBLogicalDevice[] =>
    _.reject(devices, { profile: ProfileGroups.Blocked })
);

export const getDevicesByProfileWithDetails = (profileName: string) =>
  createSelector(
    [
      (state: RootState) => getDevicesByProfile(profileName)(state),
      getDevicesDetails,
    ],
    (profileDevices, details): SBLogicalDeviceWithDetails[] => {
      return _.map(profileDevices, (device) => ({
        ...device,
        details: _.pick(details, device.identifiers),
      }));
    }
  );

export const getNotRoamingDevicesByProfile = (profileName: string) =>
  createSelector(
    [(state: RootState) => getDevicesByProfileWithDetails(profileName)(state)],
    (profileDevices): SBLogicalDeviceWithDetails[] => {
      return _.reject(profileDevices, (device) =>
        _.some(
          device.identifiers,
          (id) => device.details[id].type === LogicalDeviceDetailsTypes.Roaming
        )
      );
    }
  );

export const getRoamingDevicesByProfile = (profileName: string) =>
  createSelector([getLogicalDevices], (devicesState): MappedRoamingDevice[] =>
    pickRoamingDevicesByProfile(profileName)(devicesState)
  );

const pickRoamingDevicesByProfile =
  (profileName: string) =>
  (devices: LogicalDevicesReducerState): MappedRoamingDevice[] => {
    const allRoamingDevices = pickRoamingDevices(devices);

    return _.filter(
      allRoamingDevices,
      (device) => device.profile === profileName
    );
  };

const pickRoamingDevices = (
  devices: LogicalDevicesReducerState
): MappedRoamingDevice[] => {
  const details = devices.detailsByIdentifier;
  const roaming = _.filter(details, isRoamingType);

  return _.map(roaming, (device) => {
    const logicalDeviceId = device.logicalDeviceId;
    const savedDevice = devices.entities[logicalDeviceId];

    return mapRoamingDevice(device, savedDevice);
  });
};

interface MappedRoamingDevice {
  name: string;
  identifier: string;
  profile: string | ProfileGroups;
  lastSeen: number;
  os: string;
  softwareVersion: string;
  model: string;
  fullName: string;
  appStatus: RoamingDeviceStatus;
  protectionStatus: RoamingDeviceStatus;
  logicalDeviceId: string;
}

const mapRoamingDevice = (
  details: SBRoamingDeviceDetails,
  logicalDevice: SBLogicalDevice
): MappedRoamingDevice => {
  return {
    name: details.name,
    identifier: details.identifier,
    profile: logicalDevice.profile,
    lastSeen: logicalDevice.lastSeen,
    os: details.os,
    softwareVersion: details["software-version"],
    model: details.model,
    fullName: details.username,
    appStatus: details.status,
    protectionStatus: details.status,
    logicalDeviceId: details.logicalDeviceId,
  };
};

export const getRoamingDevices = createSelector(
  [getLogicalDevices],
  pickRoamingDevices
);

export const getRoamingDevicesCount = createSelector(
  [getRoamingDevices],
  (devices): number => devices.length
);

export const getRoamingDetailsByIdentifier = (
  state: RootState,
  identifier: string
): MappedRoamingDevice => {
  const devices = getLogicalDevices(state);
  const details = devices.detailsByIdentifier[
    identifier
  ] as SBRoamingDeviceDetails;
  const logicalDevice = devices.entities[details.logicalDeviceId];

  return mapRoamingDevice(details, logicalDevice);
};

export const isRoamingCountApproachingLimit = createSelector(
  [getRoamingLimit, getRoamingDevicesCount],
  (limit, count): boolean => {
    const threshold = Math.trunc(limit * 0.7);

    return threshold <= count && count < limit;
  }
);

export const isRoamingLimitReached = createSelector(
  [getRoamingLimit, getRoamingDevicesCount],
  (limit, count): boolean => count >= limit
);

export const isBlockedLimitReached = createSelector(
  [getLimit, getBlockedDevices],
  (limit, blocked): boolean => Boolean(limit && blocked.length >= limit / 2)
);

export const getSavedDevicesIdentifiers = createSelector(
  getDevicesDetails,
  (details): string[] => Object.keys(details)
);

export const getSavedDevicesIdentifiersMap = createSelector(
  getSavedDevicesIdentifiers,
  (savedIdentifiers): Record<string, string> => _.keyBy(savedIdentifiers)
);
