import _ from "lodash";

import { Line, LogicalDeviceDetailsTypes } from "@sportal/api";
import { ellipsisInTheMiddle } from "./ellipsis.helper";
import { normalizeMacAddress } from "./validation.helper";
import { DeviceTab } from "../components/tabList";
import {
  SBDeviceDetails,
  SBLogicalDevice,
} from "../store/devices/devices.types";

export enum DevicesSets {
  Discovered = "discovered",
  Infected = "infected",
  Blocked = "blocked",
  Roaming = "roaming",
  NotRoaming = "not-roaming", // devices with device type Mobile and Wifi
  Requests = "new-requests",
}

export const TABS: DeviceTab[] = [
  { value: DevicesSets.Discovered, name: "online" },
  { value: DevicesSets.Infected, name: "infected" },
  { value: DevicesSets.Blocked, name: "blocked" },
  { value: DevicesSets.Roaming, name: "roaming" },
];

const rejectTabItem = (type) => (tabs) => _.reject(tabs, { value: type });
export const rejectRoaming = (tabs) => rejectTabItem(DevicesSets.Roaming)(tabs);
export const rejectRequests = (tabs) =>
    rejectTabItem(DevicesSets.Requests)(tabs);
export const rejectUnassigned = (tabs) =>
    rejectTabItem(DevicesSets.NotRoaming)(tabs);

export const getTypeForIdentifier = (
    identifier: string,
    lines: Record<string, Line>,
    details: Record<string, SBDeviceDetails>
): LogicalDeviceDetailsTypes => {
  const associatedLine = lines[identifier];
  const deviceDetails = details[identifier];

  return getDeviceType(associatedLine, deviceDetails);
};

export const getIdentifiers = (devices: SBLogicalDevice[]): string[] => {
  return _.chain(devices)
      .map("identifiers")
      .reduce((arr, id) => arr.concat(id), [])
      .value();
};

export const getDeviceById = (devices, id) => {
  return _.find(devices, device => {
    if (_.has(device, "identifiers")) {
      return isAddressInList(device.identifiers, id);
    }

    return device.address === id;
  });
};

export const getNewDeviceName = (devices, deviceName) => {
  let name = deviceName;
  let index = 1;

  while (_.some(devices, { name: name })) {
    name = `${name} (${index++})`;
  }

  return name;
};

export const getDeviceType = (
    line: Line,
    details: SBDeviceDetails
): LogicalDeviceDetailsTypes => {
  if (!_.isNil(details) && details.type === LogicalDeviceDetailsTypes.Roaming) {
    return LogicalDeviceDetailsTypes.Roaming;
  }

  if (!_.isNil(line)) {
    return LogicalDeviceDetailsTypes.Line;
  }

  return LogicalDeviceDetailsTypes.Mac;
};

const UUID_MAX_LENGTH = 15;

export const ellipsifyUUID = uuid => ellipsisInTheMiddle(uuid, UUID_MAX_LENGTH);

export const isAddressInList = (list, address) => {
  const normalizedList = _.map(list, id => normalizeMacAddress(id));
  const normalizedAddress = normalizeMacAddress(address);

  return _.includes(normalizedList, normalizedAddress);
};
