import { RequestWithName } from "@sportal/api";

import { LoadingState } from "../../shared/withLoadableSlice.types";
import { withLoadingState } from "../../shared";
import {
  RequestsWithNameActionTypes,
  FETCH_REQUESTS_NAME,
  FETCH_REQUESTS_NAME_SUCCESS,
  FETCH_REQUESTS_NAME_FAILURE,
} from "./requestsWithName.types";

export interface RequestsWithNameReducerState extends LoadingState {
  list: RequestWithName[];
}

export const initialState: RequestsWithNameReducerState = {
  isLoading: false,
  success: false,
  error: null,
  list: [],
};

const reducer = (
  state = initialState,
  action: RequestsWithNameActionTypes
): RequestsWithNameReducerState => {
  switch (action.type) {
    case FETCH_REQUESTS_NAME_SUCCESS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const requestsWithNameReducer = withLoadingState({
  loadActionType: FETCH_REQUESTS_NAME,
  successActionType: FETCH_REQUESTS_NAME_SUCCESS,
  failureActionType: FETCH_REQUESTS_NAME_FAILURE,
})(reducer);
