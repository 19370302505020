import _ from "lodash";
import { createSelector } from "reselect";

import {
  Line,
  LogicalDeviceDetailsTypes,
  RequestWithName,
  Threat,
} from "@sportal/api";
import {
  getIdentifiers,
  getTypeForIdentifier,
} from "../../helpers/devices.helpers";
import { SBDeviceDetails, SBLogicalDevice } from "./devices.types";
import { getLines, getLinesList, getLinesMap } from "./lines";
import {
  getDiscoveredDevices,
  getDiscoveredDevicesList,
} from "./discovered/discovered.selectors";
import { getInfectedDevices, getInfectionsMap } from "./infectedDevices";
import {
  getRequestsWithName,
  getRequestsWithNameList,
} from "./requestsWithName/requestsWithName.selectors";
import {
  getBlockedDevices,
  getDevicesDetails,
  getLogicalDevices,
  getLogicalDevicesEntities,
  getNotBlockedDevices,
  getSavedDevicesIdentifiersMap,
} from "./logicalDevices";
import { RootState } from "../root.reducer";
import { isMultipleUserLevel } from "../account";
import { isLoaded } from "../shared";
import {getThreatsList} from "../threats/threats.selectors";
import {normalizeMacAddress} from "../../helpers/validation.helper";

export const getSavedDevicesWithLinesOnly = createSelector(
  [
    (state: RootState) => getNotBlockedDevices(state),
    (state: RootState) => getDevicesDetails(state),
    (state: RootState) => getLinesMap(state),
  ],
  (devices, details, linesMap): SBLogicalDevice[] => {
    return _.filter(devices, (device) => {
      if (device.identifiers.length > 1) return;

      const id = device.identifiers[0];
      const type = details[id].type;

      if (type !== LogicalDeviceDetailsTypes.Line) return;

      return _.has(linesMap, id);
    });
  }
);

export const getSavedDevicesWithMacsOnly = createSelector(
  [
    (state: RootState) => getNotBlockedDevices(state),
    (state: RootState) => getDevicesDetails(state),
    (state: RootState) => getLinesMap(state),
  ],
  (devices, details, linesMap): SBLogicalDevice[] => {
    return _.filter(devices, (device) => {
      if (device.identifiers.length > 1) return;

      const id = device.identifiers[0];
      const type = details[id].type;

      if (type !== LogicalDeviceDetailsTypes.Mac) return;

      return !_.has(linesMap, id);
    });
  }
);

interface DeviceToMerge {
  identifiers?: string[]; // only in profile devices
  type: LogicalDeviceDetailsTypes;
  id?: string; // only in new request + online
  name?: string; // only in new request
}

export const getDevicesForMerge =
  (device: DeviceToMerge) =>
  (state: RootState): SBLogicalDevice[] =>
    device.type === LogicalDeviceDetailsTypes.Line
      ? getSavedDevicesWithMacsOnly(state)
      : getSavedDevicesWithLinesOnly(state);

const isDeviceSaved = (id: string, savedIds: Record<string, string>): boolean =>
  _.has(savedIds, normalizeMacAddress(id));

interface NotBlockedInfectedDevice
  extends DeviceWithInfections<{ id: string }> {
  name: string;
}

export const getDevicesWithActiveRequests = createSelector(
  [
    (state: RootState) => getRequestsWithNameList(state),
    (state: RootState) => getSavedDevicesIdentifiersMap(state),
  ],
  (named, savedIds): RequestWithName[] => {
    return _(named)
      .reject(({ id }) => isDeviceSaved(id, savedIds))
      .groupBy("id")
      .map((requests) => _.head(requests))
      .orderBy("lastSeen", "asc")
      .value();
  }
);

export const getNotBlockedInfectedDevices = createSelector(
  [
    (state: RootState) => getLogicalDevicesEntities(state),
    (state: RootState) => getSavedDevicesIdentifiersMap(state),
    (state: RootState) => getDevicesDetails(state),
    (state: RootState) => getThreatsList(state),
    (state: RootState) => getInfectionsMap(state),
    (state: RootState) => getDevicesWithActiveRequests(state),
    (state: RootState) => getBlockedDevices(state),
  ],
  (
    saved,
    savedIds,
    details,
    threats,
    infected,
    activeRequests,
    blockedDevices
  ): NotBlockedInfectedDevice[] => {
    const namedRequestsMap = _.keyBy(activeRequests, "id");
    const blockedIds: string[] = getIdentifiers(blockedDevices);

    const getDeviceName = (device: { id: string }): string => {
      if (isDeviceSaved(device.id, savedIds)) {
        const { logicalDeviceId } = details[device.id];

        return saved[logicalDeviceId].name;
      }

      const requestedDevice = _.get(namedRequestsMap, device.id);
      if (requestedDevice) {
        return requestedDevice.name;
      }

      return device.id;
    };

    return _(infected)
      .omit(blockedIds)
      .map((threatsIds: number[], id) =>
        extendDeviceWithInfections<{ id: string }>({ id }, threatsIds, threats)
      )
      .map((device) => ({
        ...device,
        name: getDeviceName(device),
      }))
      .value();
  }
);

export const getLastRequests = createSelector(
  [(state: RootState) => getDevicesWithActiveRequests(state)],
  (
    namedRequests
  ): {
    requests: RequestWithName[];
    totalLength: number;
  } => {
    const ordered = _.orderBy(namedRequests, ["lastSeen"], ["desc"]);

    return {
      requests: ordered.slice(0, 4),
      totalLength: ordered.length,
    };
  }
);

export const anyNewRequests = createSelector(
  [
    (state: RootState) => getRequestsWithNameList(state),
    (state: RootState) => getSavedDevicesIdentifiersMap(state),
  ],
  (named, savedIds): boolean =>
    named.some((device) => !isDeviceSaved(device.id, savedIds))
);

export const getBlockedDevicesWithInfections = createSelector(
  [
    (state: RootState) => getBlockedDevices(state),
    (state: RootState) => getThreatsList(state),
    (state: RootState) => getInfectionsMap(state),
  ],
  (blocked, threats, infected): DeviceWithInfections<SBLogicalDevice>[] => {
    return _(blocked)
      .map((device) => {
        const threatsIds = _(infected)
          .pick(device.identifiers)
          .values()
          .flatten()
          .value();

        return extendDeviceWithInfections<SBLogicalDevice>(
          device,
          threatsIds,
          threats
        );
      })
      .value();
  }
);

interface OnlineDevice {
  id: string;
  lastSeen?: number;
  manufacturer?: string;
}

export const getOnlineDevicesForTable = createSelector(
  [
    (state: RootState) => getLinesList(state),
    (state: RootState) => getDiscoveredDevicesList(state),
    (state: RootState) => getSavedDevicesIdentifiersMap(state),
    (state: RootState) => getLinesMap(state),
    (state: RootState) => getDevicesDetails(state),
  ],
  (
    lines,
    discovered,
    savedIds,
    linesMap,
    deviceDetails
  ): EnhanceDeviceWithTypeResult<OnlineDevice>[] => {
    const onlineDevices = [...discovered, ...lines].filter(
      ({ id }) => !isDeviceSaved(id, savedIds)
    );

    const online = _.uniqBy(onlineDevices, "id");

    return enhanceDeviceWithType<OnlineDevice>(linesMap, deviceDetails, online);
  }
);

interface BlockedDeviceForTable extends DeviceWithInfections<SBLogicalDevice> {
  flatInfections: string[];
  details: Record<string, SBDeviceDetails>;
}

export const getBlockedDevicesForTable = createSelector(
  [
    (state: RootState) => getBlockedDevicesWithInfections(state),
    (state: RootState) => getDevicesDetails(state),
  ],
  (blocked, details): BlockedDeviceForTable[] => {
    return _.map(blocked, (device) => {
      return {
        ...device,
        flatInfections: _.map(device.infections, "name"),
        details: _.pick(details, device.identifiers),
      };
    });
  }
);

interface NotBlockedInfectedWithFlatInfections
  extends NotBlockedInfectedDevice {
  flatInfections: string[];
}

export const getInfectedDevicesForTable = createSelector(
  [
    (state: RootState) => getNotBlockedInfectedDevices(state),
    (state: RootState) => getLinesMap(state),
    (state: RootState) => getDevicesDetails(state),
  ],
  (
    infected,
    linesMap,
    deviceDetails
  ): EnhanceDeviceWithTypeResult<NotBlockedInfectedWithFlatInfections>[] => {
    const mapped = _.map(infected, (device) => ({
      ...device,
      flatInfections: _.map(device.infections, "name"),
    }));

    return enhanceDeviceWithType<NotBlockedInfectedWithFlatInfections>(
      linesMap,
      deviceDetails,
      mapped
    );
  }
);

export const getRequestedDevicesForTable = createSelector(
  [
    (state: RootState) => getDevicesWithActiveRequests(state),
    (state: RootState) => getLinesMap(state),
    (state: RootState) => getDevicesDetails(state),
  ],
  (
    requested,
    linesMap,
    deviceDetails
  ): EnhanceDeviceWithTypeResult<RequestWithName>[] => {
    return enhanceDeviceWithType<RequestWithName>(
      linesMap,
      deviceDetails,
      requested
    );
  }
);

type FullDetailedThreat =
  | Threat
  | { id: number; notFullThreat: true; name: string };

const sortInfectionsBySeverity = (
  infections: FullDetailedThreat[]
): FullDetailedThreat[] =>
  _.orderBy(infections, ["severity", "name"], ["desc", "asc"]);

type ThreatsMap = Record<string, Threat>;

const convertThreatIdsToFullDetails = (
  threatsIds: number[],
  threats: ThreatsMap
): FullDetailedThreat[] => {
  return _.map(threatsIds, (id) =>
    _.get(threats, id, { id, notFullThreat: true, name: "" + id })
  );
};

type DeviceWithInfections<D> = D & { infections: FullDetailedThreat[] };

const extendDeviceWithInfections = <D>(
  device: D,
  threatsIds: number[],
  threats: ThreatsMap
): DeviceWithInfections<D> => {
  const fullThreats = convertThreatIdsToFullDetails(threatsIds, threats);

  return {
    ...device,
    infections: sortInfectionsBySeverity(fullThreats),
  };
};

type EnhanceDeviceWithTypeResult<D> = D & {
  type: LogicalDeviceDetailsTypes;
};

const enhanceDeviceWithType = <D extends { id: string }>(
  linesMap: Record<string, Line>,
  details: Record<string, SBDeviceDetails>,
  devices: D[]
): EnhanceDeviceWithTypeResult<D>[] => {
  // @ts-ignore
    return _.map(devices, (device) => ({
    ...device,
    type: getTypeForIdentifier(device.id, linesMap, details),
  }));
};

export const getTypesForIds = (
  state: RootState,
  identifiers: string[]
): Record<string, LogicalDeviceDetailsTypes> => {
  const linesMap = getLinesMap(state);
  const details = getDevicesDetails(state);

  return _.reduce(
    identifiers,
    (memo, identifier) => ({
      ...memo,
      [identifier]: getTypeForIdentifier(identifier, linesMap, details),
    }),
    {}
  );
};

export const areAllHomePageDevicesLoaded = (state: RootState): boolean => {
  const isMultiple = isMultipleUserLevel(state);

  const logicalDevicesSlice = getLogicalDevices(state);
  const infectedDevicesSlice = getInfectedDevices(state);
  const linesSlice = getLines(state);
  const discoveredDevicesSlice = getDiscoveredDevices(state);
  const devicesWithRequestsSlice = getRequestsWithName(state);

  const areLogicalDevicesLoaded = isLoaded(logicalDevicesSlice);

  if (isMultiple) {
    const areInfectedDevicesLoaded = isLoaded(infectedDevicesSlice);
    const areLinesLoaded = isLoaded(linesSlice);
    const areDiscoveredDevicesLoaded = isLoaded(discoveredDevicesSlice);
    const areDevicesWithRequestsLoaded = isLoaded(devicesWithRequestsSlice);

    return (
      areLogicalDevicesLoaded &&
      areInfectedDevicesLoaded &&
      areLinesLoaded &&
      areDiscoveredDevicesLoaded &&
      areDevicesWithRequestsLoaded
    );
  }

  return areLogicalDevicesLoaded;
};
