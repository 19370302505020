import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Icon } from "../../../../components/icon/Icon";
import { Button } from "../../../../components/button/Button";
import { Table } from "../../../../components/table/Table";
import { getRequestedDevicesForTable } from "../../../../store/devices/devices.selectors";
import { DeviceWithType } from "../../../../components/devices/DeviceWithType";
import { Manufacturer } from "../../../../components/manufacturer/Manufacturer";
import { TableExpandableCard } from "../../../../components/table/components/TableExpandableCard";
import { NotMobile, Mobile } from "../../../../hocs/Responsive";
import { RequestedDevicesDropdownActions } from "./RequestedDevicesDropdownActions";
import { singleDeviceIdSort } from "../../../../components/table/helpers/sorting.helper";
import {
  DeviceNameCell,
  DeviceNameCellMobile
} from "../../../../components/table";
import {fetchRequestsWithName} from "../../../../store/devices/requestsWithName";

const COLUMNS = [
  {
    Header: <FormattedMessage id={"device_name"} />,
    accessor: "name",
    sortable: true,
    Cell: ({
      row: {
        original: { name }
      }
    }) => <DeviceNameCell name={name} />
  },
  {
    Header: <FormattedMessage id={"device_id"} />,
    accessor: "id",
    sortable: true,
    Cell: ({
      row: {
        original: { id, type }
      }
    }) => <DeviceWithType type={type} identifier={id} />,
    sortType: singleDeviceIdSort
  },
  {
    Header: <FormattedMessage id={"manufacturer"} />,
    accessor: "manufacturer",
    sortable: true,
    cellClassName: "color-secondary",
    Cell: ({
      row: {
        original: { manufacturer }
      }
    }) => <Manufacturer manufacturer={manufacturer} />
  },
  {
    Header: <FormattedMessage id={"actions"} />,
    id: "actions",
    sortable: false,
    headerClassName: "table__cell--small-cell",
    cellClassName: "table__cell--center table__cell--overflow",
    Cell: ({
      row: {
        original: { id, name, type }
      }
    }) => (
      <RequestedDevicesDropdownActions
        id={id}
        name={name}
        type={type}
      />
    )
  }
];

const COLUMNS_MOBILE = [
  {
    accessor: "name",
    cellClassName: "table__cell--overflow",
    SortingLabel: <FormattedMessage id={"device_name"} />,
    Cell: ({
      row: {
        original: { name, id, manufacturer, type }
      }
    }) => (
      <TableCard
        type={type}
        id={id}
        manufacturer={manufacturer}
        name={name}
      />
    )
  },
  {
    accessor: "id",
    SortingLabel: <FormattedMessage id={"device_id"} />,
    sortType: singleDeviceIdSort
  },
  {
    accessor: "manufacturer",
    SortingLabel: <FormattedMessage id={"manufacturer"} />
  }
];

const SEARCH_KEYS = ["id", "manufacturer", "name"];

export const RequestedDevicesTable = () => {
  const devices = useSelector(getRequestedDevicesForTable, _.isEqual);

  return (
    <Fragment>
      <RequestedDevicesInfo />
      <Mobile>
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          showMobileSort
          visibleHeader={false}
          columns={COLUMNS_MOBILE}
          data={devices}
          hiddenColumns={["manufacturer", "id"]}
          searchAriaLabelMessage="aria_search_for_device"
        />
      </Mobile>
      <NotMobile>
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          columns={COLUMNS}
          data={devices}
          searchAriaLabelMessage="aria_search_for_device"
        />
      </NotMobile>
    </Fragment>
  );
};

export const RequestedDevicesInfo = () => {
  const dispatch = useDispatch();

  return (
    <div className={"devices__refresh"}>
      <Button
        isOutline
        size={"large"}
        onClick={() => dispatch(fetchRequestsWithName())}
      >
        <Icon icon="far fa-sync-alt color-secondary" />
        <span>
          <FormattedMessage id={"refresh"} />
        </span>
      </Button>
    </div>
  );
};

const TableCard = ({ type, id, manufacturer, name }) => {
  return (
    <TableExpandableCard
      showExpand={false}
      content={
        <Fragment>
          <DeviceNameCellMobile name={name} />
          <DeviceWithType type={type} identifier={id} />
          {manufacturer && (
            <div className={"color-secondary"}>{manufacturer}</div>
          )}
        </Fragment>
      }
      aside={
        <RequestedDevicesDropdownActions
          id={id}
          name={name}
          type={type}
        />
      }
    />
  );
};
