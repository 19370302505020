import { RequestWithName } from "@sportal/api";

import { RequestsWithNameReducerState } from "./requestsWithName.reducer";
import { RootState } from "../../root.reducer";

export const getRequestsWithName = (
  state: RootState
): RequestsWithNameReducerState => state.devices.withName;

export const getRequestsWithNameList = (state: RootState): RequestWithName[] =>
  getRequestsWithName(state).list;
