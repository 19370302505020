import {
  LogicalDevice,
  LogicalDevicesData,
} from "@sportal/api";
import { SBLogicalDevice } from "../devices.types";
import {
  RemoveProfileSuccessAction,
  RenameProfileSuccessAction,
} from "../../profiles/profiles.types";

export const FETCH_LOGICAL_DEVICES = "[DEVICES] FETCH_LOGICAL_DEVICES";
export const FETCH_LOGICAL_DEVICES_SUCCESS =
  "[DEVICES] FETCH_LOGICAL_DEVICES_SUCCESS";
export const FETCH_LOGICAL_DEVICES_FAILURE =
  "[DEVICES] FETCH_LOGICAL_DEVICES_FAILURE";
export const SAVE_DEVICE_SUCCESS = "[DEVICE] SAVE_DEVICE_SUCCESS";
export const SAVE_DEVICE_FAILURE = "[DEVICE] SAVE_DEVICE_FAILURE";
export const EDIT_DEVICE_SUCCESS = "[DEVICES] EDIT_DEVICE_SUCCESS";
export const EDIT_DEVICE_FAILURE = "[DEVICES] EDIT_DEVICE_FAILURE";
export const MERGE_DEVICE_SUCCESS = "[DEVICES] MERGE_DEVICE_SUCCESS";
export const MERGE_DEVICE_FAILURE = "[DEVICES] MERGE_DEVICE_FAILURE";
export const UNMERGE_DEVICE_SUCCESS = "[DEVICES] UNMERGE_DEVICE_SUCCESS";
export const UNMERGE_DEVICE_FAILURE = "[DEVICES] UNMERGE_DEVICE_FAILURE";
export const REMOVE_DEVICE_SUCCESS = "[DEVICE] REMOVE_DEVICE_SUCCESS";
export const REMOVE_DEVICE_FAILURE = "[DEVICE] REMOVE_DEVICE_FAILURE";
export const EDIT_ROAMING_DEVICE_SUCCESS =
  "[DEVICES] EDIT_ROAMING_DEVICE_SUCCESS";
export const EDIT_ROAMING_DEVICE_FAILURE =
  "[DEVICES] EDIT_ROAMING_DEVICE_FAILURE";
export const REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS =
  "[DEVICES] REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS";
export const REVOKE_ROAMING_DEVICE_ACCESS_FAILURE =
  "[DEVICES] REVOKE_ROAMING_DEVICE_ACCESS_FAILURE";
export const FETCH_ROAMING_LIMIT_SUCCESS =
  "[DEVICES] FETCH_ROAMING_LIMIT_SUCCESS";
export const FETCH_ROAMING_LIMIT_FAILURE =
  "[DEVICES] FETCH_ROAMING_LIMIT_FAILURE";

export interface SpsonDeviceUpdateAttributes {
  name?: string;
  username?: string;
}

export enum RoamingDeviceStatus {
  Enabled = "enabled",
  Disabled = "disabled",
}

interface fetchLogicalDevicesRequestAction {
  type: typeof FETCH_LOGICAL_DEVICES;
}

interface fetchLogicalDevicesSuccessAction {
  type: typeof FETCH_LOGICAL_DEVICES_SUCCESS;
  payload: LogicalDevicesData;
}

interface fetchLogicalDevicesFailureAction {
  type: typeof FETCH_LOGICAL_DEVICES_FAILURE;
  payload: object & { code: number | string };
}

interface SaveDeviceSuccessAction {
  type: typeof SAVE_DEVICE_SUCCESS;
  payload: LogicalDevice;
}

interface SaveDeviceFailureAction {
  type: typeof SAVE_DEVICE_FAILURE;
  payload: object & { code: number | string };
}

interface EditDeviceSuccessAction {
  type: typeof EDIT_DEVICE_SUCCESS;
  payload: {
    device: LogicalDevice & { logicalDeviceId?: string };
  };
}
interface EditDeviceFailureAction {
  type: typeof EDIT_DEVICE_FAILURE;
  payload: object & { code: number | string };
}

interface MergeDeviceSuccessAction {
  type: typeof MERGE_DEVICE_SUCCESS;
  payload: {
    deviceToUpdate: SBLogicalDevice;
    deviceToRemoveId?: string;
  };
}

interface MergeDeviceFailureAction {
  type: typeof MERGE_DEVICE_FAILURE;
  payload: object & { code: number | string };
}

interface UnmergeDeviceSuccessAction {
  type: typeof UNMERGE_DEVICE_SUCCESS;
  payload: {
    deviceToUpdate: SBLogicalDevice;
    deviceToAdd: LogicalDevice;
  };
}

interface UnmergeDeviceFailureAction {
  type: typeof UNMERGE_DEVICE_FAILURE;
  payload: object & { code: number | string };
}

interface RemoveDeviceSuccessAction {
  type: typeof REMOVE_DEVICE_SUCCESS;
  payload: string;
}

interface RemoveDeviceFailureAction {
  type: typeof REMOVE_DEVICE_FAILURE;
  payload: object & { code: number | string };
}

export interface EditRoamingDeviceSuccessAction {
  type: typeof EDIT_ROAMING_DEVICE_SUCCESS;
  payload: {
    identifier: string;
    changes: SpsonDeviceUpdateAttributes;
  };
}

export interface EditRoamingDeviceFailureAction {
  type: typeof EDIT_ROAMING_DEVICE_FAILURE;
  payload: object & { code: number | string };
}

interface RevokeDeviceAccessSuccessAction {
  type: typeof REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS;
  payload: string;
}

interface RevokeDeviceAccessFailureAction {
  type: typeof REVOKE_ROAMING_DEVICE_ACCESS_FAILURE;
  payload: object & { code: number | string };
}

interface FetchRoamingLimitSuccessAction {
  type: typeof FETCH_ROAMING_LIMIT_SUCCESS;
  payload: number;
}

interface FetchRoamingLimitFailureAction {
  type: typeof FETCH_ROAMING_LIMIT_FAILURE;
  payload: object & { code: number | string };
}

export type LogicalDevicesActionTypes =
  | fetchLogicalDevicesRequestAction
  | fetchLogicalDevicesSuccessAction
  | fetchLogicalDevicesFailureAction
  | SaveDeviceSuccessAction
  | SaveDeviceFailureAction
  | EditDeviceSuccessAction
  | EditDeviceFailureAction
  | MergeDeviceSuccessAction
  | MergeDeviceFailureAction
  | UnmergeDeviceSuccessAction
  | UnmergeDeviceFailureAction
  | RemoveDeviceSuccessAction
  | RemoveDeviceFailureAction
  | EditRoamingDeviceSuccessAction
  | EditRoamingDeviceFailureAction
  | RevokeDeviceAccessSuccessAction
  | RevokeDeviceAccessFailureAction
  | FetchRoamingLimitSuccessAction
  | FetchRoamingLimitFailureAction
  | RemoveProfileSuccessAction
  | RenameProfileSuccessAction;
