import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { useMount } from "@sportal/cdk/hooks";
import { Icon } from "../../../../components/icon/Icon";
import { ModalService } from "../../../../components/modal/index";
import AddDeviceDialog from "./AddDeviceDialog";
import {
  getMappedProfilesForSelect,
  isAddDeviceDisabled
} from "../../../../store/profiles/profiles.selectors";
import { Button } from "../../../../components/button/Button";
import {fetchLogicalDevices, getLogicalDevicesEntities, saveDevice} from "../../../../store/devices/logicalDevices";

import "./AddDevice.scss";

const openAddDeviceModal = currentProfile => (dispatch, getState) => {
  const state = getState();
  const profiles = getMappedProfilesForSelect(state);
  const allDevices = getLogicalDevicesEntities(state);

  ModalService.show(modal => ({
    dialog: (
      <AddDeviceDialog
        profiles={profiles}
        allDevices={allDevices}
        currentProfile={currentProfile}
        modal={modal}
      />
    )
  }))
    .then(device => {
      dispatch(saveDevice(device));
    })
    .catch(() => null);
};

interface AddDeviceButtonProps {
  className?: string;
  currentProfile?: string;
}

const AddDeviceButton = ({
  className,
  currentProfile
}: AddDeviceButtonProps) => {
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(fetchLogicalDevices());
  });

  const isDisabled = useSelector(isAddDeviceDisabled);

  const handleClick = () => {
    dispatch(openAddDeviceModal(currentProfile));
  };

  return (
    <div className={className}>
      <Button
        className="add-device-link"
        disabled={isDisabled}
        onClick={handleClick}
        data-testid="add-device-button"
        isOutline
      >
        <Icon icon="far fa-plus-circle" />
        <span>
          <FormattedMessage id="add_device" />
        </span>
      </Button>
    </div>
  );
};

AddDeviceButton.propTypes = {
  className: PropTypes.string,
  currentProfile: PropTypes.string
};

export const AddDevice = memo(AddDeviceButton);
