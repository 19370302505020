import { HttpService } from "../shared";
import { InfectedDevicesServerData } from "./infectedDevices.types";

export class InfectedDevicesProvider {
  constructor(private http: HttpService, private baseUrl: string) {}

  private get url() {
    return `${this.baseUrl}/ssm/series/ss/infections-by-device`;
  }

  public get(subscriberId: string, duration: string) {
    return this.http.get<InfectedDevicesServerData>(
      `${this.url}?view=${subscriberId}&duration=${duration}`
    );
  }
}
