import React from "react";
import {FormattedMessage} from "react-intl";

import { RequestWithName, isFailure } from "@sportal/api";
import {
  FETCH_REQUESTS_NAME,
  FETCH_REQUESTS_NAME_SUCCESS,
  FETCH_REQUESTS_NAME_FAILURE,
  RequestsWithNameActionTypes,
} from "./requestsWithName.types";
import { SBThunkAction } from "../../redux.types";
import { getSubscriberId } from "../../info/info.selectors";
import Notificator from "../../../components/notification/notification.actions";
import {AddNotificationAction} from "../../../components/notification/notification.types";

export const fetchRequestsWithName =
  (
    initialLoad = false
  ): SBThunkAction<
    void,
    RequestsWithNameActionTypes | AddNotificationAction
  > =>
  async (dispatch, getState, { api }) => {
    if (initialLoad) {
      dispatch({ type: FETCH_REQUESTS_NAME });
    }

    const subscriberId = getSubscriberId(getState());
    const result = await api.ssm.deviceRequests.getDevicesWithName(subscriberId);

    if (isFailure(result)) {
      dispatch(
          Notificator.error(
              <FormattedMessage id={"error_failed_to_load_manufacturers"} />
          )
      );
      dispatch(fetchRequestsWithNameFailure(result.error));
      return;
    }

    dispatch(fetchRequestsWithNameSuccess(result.data));
  };

export const fetchRequestsWithNameSuccess = (
  requestsWithName: RequestWithName[]
): RequestsWithNameActionTypes => ({
  type: FETCH_REQUESTS_NAME_SUCCESS,
  payload: requestsWithName,
});

const fetchRequestsWithNameFailure = (
  error: object & { code: string | number }
): RequestsWithNameActionTypes => ({
  type: FETCH_REQUESTS_NAME_FAILURE,
  payload: error,
});
