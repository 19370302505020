import React from "react";
import {FormattedMessage} from "react-intl";

import { InfectedDeviceServerData, isFailure } from "@sportal/api";

import {
  FETCH_INFECTED,
  FETCH_INFECTED_SUCCESS,
  FETCH_INFECTED_FAILURE,
  InfectedDevicesActionTypes,
} from "./infectedDevices.types";

import { SBThunkAction } from "../../redux.types";
import { getSubscriberId } from "../../info/info.selectors";
import {fetchThreats} from "../../threats/threats.actions";
import Notificator from "../../../components/notification/notification.actions";
import {AddNotificationAction} from "../../../components/notification/notification.types";


export const fetchInfected =
  (): SBThunkAction<
    void,
    InfectedDevicesActionTypes | AddNotificationAction
  > =>
  async (dispatch, getState, { api }) => {
    dispatch({ type: FETCH_INFECTED });

    const subscriberId = getSubscriberId(getState());
    const result = await api.ssm.infectedDevices.get(subscriberId);

    if (isFailure(result)) {
      dispatch(
          Notificator.error(<FormattedMessage id={"failed_to_load_infected"} />)
      );
      dispatch(fetchInfectedFailure(result.error));
      return;
    }

    dispatch(fetchThreats());
    dispatch(fetchInfectedSuccess(result.data));
  };

export const fetchInfectedSuccess = (
  devices: InfectedDeviceServerData
): InfectedDevicesActionTypes => ({
  type: FETCH_INFECTED_SUCCESS,
  payload: devices,
});

const fetchInfectedFailure = (
  error: object & { code: number | string }
): InfectedDevicesActionTypes => ({
  type: FETCH_INFECTED_FAILURE,
  payload: error,
});
