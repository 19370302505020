import { getSubscriberInfo } from "../root.selectors";
import { AuthMethod } from "@sportal/api";
import { RootState } from "../root.reducer";

export const getAuthMethod = (state: RootState): AuthMethod =>
  getSubscriberInfo(state).authMethod;

export const isSSO = (state: RootState): boolean =>
  getAuthMethod(state) === AuthMethod.SSO;

export const getSubscriberId = (state: RootState): string =>
    getSubscriberInfo(state).id;