import React from "react";
import {FormattedMessage} from "react-intl";

import { isFailure, Line } from "@sportal/api";
import {
  FETCH_LINES,
  FETCH_LINES_FAILURE,
  FETCH_LINES_SUCCESS,
  LineActionTypes,
} from "./lines.types";
import { SBThunkAction } from "../../redux.types";
import { getLines } from "../lines";
import { shouldLoad } from "../../shared";
import Notificator from "../../../components/notification/notification.actions";
import {AddNotificationAction} from "../../../components/notification/notification.types";
import { getSubscriberId } from "../../info/info.selectors";

export const fetchLines =
  (): SBThunkAction<void, LineActionTypes | AddNotificationAction> =>
  async (dispatch, getState, { api }) => {
    const lines = getLines(getState());

    if (!shouldLoad(lines)) return;

    dispatch({ type: FETCH_LINES });
    const subscriberId = getSubscriberId(getState());
    const result = await api.ssm.lines.get(subscriberId);

    if (isFailure(result)) {
      dispatch(
          Notificator.error(<FormattedMessage id={"failed_to_load_lines"} />)
      );
      dispatch(fetchLinesFailure(result.error));
      return;
    }

    dispatch(fetchLinesSuccess(result.data));
  };

export const fetchLinesSuccess = (lines: Line[]): LineActionTypes => ({
  type: FETCH_LINES_SUCCESS,
  payload: lines,
});

const fetchLinesFailure = (
  error: object & { code: number | string }
): LineActionTypes => ({
  type: FETCH_LINES_FAILURE,
  payload: error,
});
