import {
  DeviceRequestsProvider,
  DeviceRequestsService,
  HttpService,
} from "@sportal/api";
import { makeManufacturerService } from "./manufacturerService";

export const makeDeviceRequestsService = (
  http: HttpService,
  baseUrl: string
): DeviceRequestsService =>
  new DeviceRequestsService(
    new DeviceRequestsProvider(http, baseUrl),
    makeManufacturerService(http, baseUrl)
  );
