import { LoadingState } from "../../shared/withLoadableSlice.types";
import { withLoadingState } from "../../shared";
import {
  LineActionTypes,
  FETCH_LINES,
  FETCH_LINES_SUCCESS,
  FETCH_LINES_FAILURE,
} from "./lines.types";
import { Line } from "@sportal/api";

export interface LineDevicesReducerState extends LoadingState {
  list: Line[];
}

export const initialState: LineDevicesReducerState = {
  isLoading: false,
  success: false,
  error: null,
  list: [],
};

const linesReducer = (
  state = initialState,
  action: LineActionTypes
): LineDevicesReducerState => {
  switch (action.type) {
    case FETCH_LINES_SUCCESS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const linesDevicesReducer = withLoadingState({
  loadActionType: FETCH_LINES,
  successActionType: FETCH_LINES_SUCCESS,
  failureActionType: FETCH_LINES_FAILURE,
})(linesReducer);
