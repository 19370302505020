import {
  DeviceDetails,
  DiscoveredDevice,
  LogicalDevice,
  LogicalDeviceDetails,
  LogicalDeviceDetailsTypes,
  LogicalDeviceServerData,
  RequestWithName
} from "@sportal/api";
import { DiscoveredActionTypes } from "./discovered";
import { RequestsWithNameActionTypes } from "./requestsWithName";
import { LineActionTypes } from "./lines";
import { InfectedDevicesActionTypes } from "./infectedDevices";
import {LogicalDevicesActionTypes} from "./logicalDevices";

// region actions

export const FETCH_DISCOVERED = "[DEVICES] FETCH_DISCOVERED";
export const FETCH_DISCOVERED_SUCCESS = "[DEVICES] FETCH_DISCOVERED_SUCCESS";
export const FETCH_DISCOVERED_FAILURE = "[DEVICES] FETCH_DISCOVERED_FAILURE";
export const FETCH_LINES = "[DEVICES] FETCH_LINES";
export const FETCH_LINES_SUCCESS = "[DEVICES] FETCH_LINES_SUCCESS";
export const FETCH_LINES_FAILURE = "[DEVICES] FETCH_LINES_FAILURE";
export const FETCH_REQUESTS_NAME = "[DEVICES] FETCH_REQUESTS_NAME";
export const FETCH_REQUESTS_NAME_SUCCESS =
  "[DEVICES] FETCH_REQUESTS_NAME_SUCCESS";
export const FETCH_REQUESTS_NAME_FAILURE =
  "[DEVICES] FETCH_REQUESTS_NAME_FAILURE";
export const FETCH_INFECTED = "[DEVICES] FETCH_INFECTED";
export const FETCH_INFECTED_SUCCESS = "[DEVICES] FETCH_INFECTED_SUCCESS";
export const FETCH_INFECTED_FAILURE = "[DEVICES] FETCH_INFECTED_FAILURE";
export const FETCH_ALL_DEVICES = "[DEVICES] FETCH_ALL_DEVICES";
export const FETCH_ALL_DEVICES_SUCCESS = "[DEVICES] FETCH_ALL_DEVICES_SUCCESS";
export const FETCH_ALL_DEVICES_FAILURE = "[DEVICES] FETCH_ALL_DEVICES_FAILURE";
export const SAVE_DEVICE_SUCCESS = "[DEVICE] SAVE_DEVICE_SUCCESS";
export const SAVE_DEVICE_FAILURE = "[DEVICE] SAVE_DEVICE_FAILURE";
export const REMOVE_DEVICE_SUCCESS = "[DEVICE] REMOVE_DEVICE_SUCCESS";
export const REMOVE_DEVICE_FAILURE = "[DEVICE] REMOVE_DEVICE_FAILURE";
export const EDIT_DEVICE_SUCCESS = "[DEVICES] EDIT_DEVICE_SUCCESS";
export const EDIT_DEVICE_FAILURE = "[DEVICES] EDIT_DEVICE_FAILURE";
export const MERGE_DEVICE_SUCCESS = "[DEVICES] MERGE_DEVICE_SUCCESS";
export const MERGE_DEVICE_FAILURE = "[DEVICES] MERGE_DEVICE_FAILURE";
export const UNMERGE_DEVICE_SUCCESS = "[DEVICES] UNMERGE_DEVICE_SUCCESS";
export const UNMERGE_DEVICE_FAILURE = "[DEVICES] UNMERGE_DEVICE_FAILURE";
export const EDIT_ROAMING_DEVICE_SUCCESS =
  "[DEVICES] EDIT_ROAMING_DEVICE_SUCCESS";
export const EDIT_ROAMING_DEVICE_FAILURE =
  "[DEVICES] EDIT_ROAMING_DEVICE_FAILURE";
export const REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS =
  "[DEVICES] REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS";
export const REVOKE_ROAMING_DEVICE_ACCESS_FAILURE =
  "[DEVICES] REVOKE_ROAMING_DEVICE_ACCESS_FAILURE";
export const CHECK_CODE_FAILURE = "[DEVICES] CHECK_CODE_FAILURE";
export const FETCH_ROAMING_LIMIT_SUCCESS =
  "[DEVICES] FETCH_ROAMING_LIMIT_SUCCESS";
export const FETCH_ROAMING_LIMIT_FAILURE =
  "[DEVICES] FETCH_ROAMING_LIMIT_FAILURE";

export enum RoamingDeviceStatus {
  Enabled = "enabled",
  Disabled = "disabled",
}

export interface RoamingDeviceDetails extends DeviceDetails {
  type: LogicalDeviceDetailsTypes.Roaming;
  model: string;
  os: string;
  "software-version": string;
  status: RoamingDeviceStatus;
  username: string;
}

// endregion

// region payloads
export interface LogicalDevicesPayload {
  plainDevices: SBLogicalDevice[];
  identifiers: Record<string, SBDeviceDetails>;
}

export interface EditRoamingDeviceChangesPayload {
  name: string;
  username: string;
}

export interface SBDiscoveredDevice extends Omit<DiscoveredDevice, "id"> {
  address: string;
}

export interface SBRequestWithName extends Omit<RequestWithName, "id"> {
  address: string;
}

export interface SBLogicalDevice extends Omit<LogicalDevice, "deviceDetails"> {
  logicalDeviceId: string;
}

export interface SBDeviceDetails extends DeviceDetails {
  logicalDeviceId: string;
}

export interface SBLogicalDeviceServerData extends LogicalDeviceServerData {
  "device-details": LogicalDeviceDetails[];
}

interface SBBaseDeviceDetails {
  logicalDeviceId: string;
}

export interface SBLogicalDeviceWithDetails extends SBLogicalDevice {
  details: Record<string, SBDeviceDetails>;
}

export interface SBRoamingDeviceDetails
    extends RoamingDeviceDetails,
        SBBaseDeviceDetails {}

export const isRoamingType = (
    details: SBDeviceDetails
): details is SBRoamingDeviceDetails =>
    details.type === LogicalDeviceDetailsTypes.Roaming;

export type LogicalDeviceUpdate = Omit<SBLogicalDeviceServerData, "last-seen">;

export type LogicalDeviceUpdates = Omit<
  SBLogicalDeviceServerData,
  "last-seen" | "device-details"
>;

// endregion
export type DevicesActionTypes =
    | DiscoveredActionTypes
    | RequestsWithNameActionTypes
    | LineActionTypes
    | InfectedDevicesActionTypes
    | LogicalDevicesActionTypes;
