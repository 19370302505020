import _ from "lodash";

import { Profile } from "@sportal/api";
import { uuid } from "../../utils/uuid";
import { validationRules } from "./profiles.validation";
import { isProtectionChanged } from "../../pages/profiles/components/webFilters/webFilters.actions";
import { ssProfileChanged } from "../../pages/profiles/components/internetSecurity/subscriberSafety/ssProfiles.reducer";
import { schedulesChanged } from "../../pages/profiles/components/schedule/schedules.selectors";
import { getDevices } from "../root.selectors";
import { ProfileGroups } from "./fixedProfiles";
import { getChangedFields, sortProfiles } from "./profiles.helpers";
import { ProfilesState } from "./profiles.types";
import { subscriberActivated } from "../account";
import { getCustomProtections } from "../../pages/profiles/components/webFilters/webFilters.selectors";
import {
  getDevicesByProfile,
  getLimit,
  getLogicalDevicesEntities,
  getNotRoamingDevicesByProfile
} from "../devices/logicalDevices";

export const addItemsToList = (items = [], list = { keys: [], list: {} }) => {
  const itemsWithId = _.map(items, item => _.extend({ id: uuid() }, item));

  return {
    keys: _.uniq([...list.keys, ..._.map(itemsWithId, "id")]),
    list: { ...list.list, ..._.keyBy(itemsWithId, "id") }
  };
};

export const removeItemFromList = (itemId, list) => ({
  keys: _.without(list.keys, itemId),
  list: _.omit(list.list, itemId)
});

export const removeItemsFromList = (itemIds = [], list) => ({
  keys: _.without(list.keys, ...itemIds),
  list: _.omit(list.list, itemIds)
});

export const getAddedItems = ({ saved, changed }: ProfilesState) =>
  _.pick(changed.list, _.difference(changed.keys, saved.keys));

export const getRemovedItems = ({ saved, changed }) =>
  _.values(_.pick(saved.list, _.difference(saved.keys, changed.keys)));

export const getSavedProfiles = ({ profiles: { saved } }) => _.cloneDeep(saved);

export const getSavedProfilesList = _.flow(
  getSavedProfiles,
  ({ list, keys }) => _.at(list, keys)
);

export const getDefaultProfile = _.flow(
  getSavedProfilesList,
  profiles => _.find(profiles, ["default", true])
);

export const getCurrentProfiles = ({ profiles: { changed } }) =>
  _.cloneDeep(changed);

export const getVisibleProfiles = state => {
  const { list, keys } = getCurrentProfiles(state);
  const sorted = sortProfiles(_.at(list, keys));

  return _.reject(sorted, { hidden: true });
};

export const getVisibleProfilesWithKeys = keys => state =>
  getVisibleProfiles(state).map(profile => _.pick(profile, keys));

export const getProfileCustomProtections = profileId => state =>
  getCustomProtections(state)[profileId];

export const getWizardSelectedProfileId = state => {
  const profilesIds = getVisibleProfilesWithKeys(["id"])(state).map(
    ({ id }) => id
  );
  const selectedProfileId = state.profiles.selected;
  const defaultProfile = getDefaultProfile(state) as Profile;

  if (!defaultProfile) return selectedProfileId;

  return _.includes(profilesIds, selectedProfileId)
    ? selectedProfileId
    : defaultProfile.id;
};

export const isValidProfileId = questionableProfileId => state => {
  const profilesIds = getVisibleProfilesWithKeys(["id"])(state).map(
    ({ id }) => id
  );

  return _.includes(profilesIds, questionableProfileId);
};

export const hasChanges = (
  id,
  { profiles, ssProfiles, webFilters, schedules }
) => {
  const { protection } = profiles.changed.list[id];
  const profileFieldChanged = !_.isEmpty(getChangedFields(id, profiles));
  const ssChanged = ssProfileChanged(id, ssProfiles);
  const protectionChanged = isProtectionChanged(
    { webFilters },
    { id, protection }
  );
  const scheduleChanged = schedulesChanged(id, schedules);

  return (
    profileFieldChanged || ssChanged || protectionChanged || scheduleChanged
  );
};

export const getMappedProfilesForSelect = state => {
  const profilesForAddingDevices = getProfilesForAddingDevices(
    state.profiles.saved
  );
  const profilesAvailableForAdding = getProfilesAvailableForAddingDevices(
    state
  );

  return _.map(profilesForAddingDevices, profile => ({
    value: profile.name,
    name: isCustom(profile) ? profile.name : profile.type,
    isDisabled: !_.includes(profilesAvailableForAdding, profile.name)
  }));
};

export const isAddDeviceDisabled = _.flow(
  getMappedProfilesForSelect,
  profiles => !_.find(profiles, profile => !profile.isDisabled)
);

export const validateProfileName = name => {
  let error = "";

  _.each(validationRules, rule => {
    error = rule(name);
    return !error;
  });

  return error;
};

export const getProfileByName = profileName => state =>
  _.find(state.profiles.saved.list, profile => profileName === profile.name);

export const getProfileById = profileId => state =>
  getCurrentProfiles(state).list[profileId];

function getProfilesAvailableForAddingDevices(state) {
  const {
    profiles: { saved }
  } = state;
  const devices = getLogicalDevicesEntities(state);
  const limit = getDevices(state).limit;

  const getProfileDevices = profile => {
    const profileDevices = _.filter(
      devices,
      device => device.profile === profile.name
    );
    return _.map(profileDevices, device => device.name);
  };

  const profilesForAddingDevices = getProfilesForAddingDevices(saved);
  const filtered = _.filter(profilesForAddingDevices, profile =>
    limit ? getProfileDevices(profile).length < Math.floor(limit / 2) : true
  );

  return _.map(filtered, "name");
}

function getProfilesForAddingDevices(profiles) {
  const toExclude = [ProfileGroups.Blocked];
  const sortedProfiles = sortProfiles(profiles.list);

  return _.filter(
    sortedProfiles,
    profile => !_.includes(toExclude, profile.name)
  );
}

function isCustom(profile) {
  return profile.type === "custom";
}

export const hasDefaultProfileNonRoamingDevices = state => {
  const defaultProfile = getDefaultProfile(state) as Profile;
  const notRoamingDevices = getNotRoamingDevicesByProfile(defaultProfile.name)(
    state
  );

  return !_.isEmpty(notRoamingDevices);
};

export const hasProfileReachedLimit = profileName => state => {
  const devices = getDevicesByProfile(profileName)(state);
  const limit = getLimit(state);

  return limit === 0 ? false : devices.length >= Math.floor(limit / 2);
};

export const haveNonBlockedProfilesReachedLimit = state => {
  const profiles = getProfilesForAddingDevices(state.profiles.saved);
  const limit = getLimit(state);

  if (limit === 0) {
    return false;
  }

  return _.every(profiles, profile =>
    hasProfileReachedLimit(profile.name)(state)
  );
};

export const isAssignActionDisabled = (state, profileToExclude = "") => {
  const profiles = getMappedProfilesForSelect(state);
  const profileChoices = profiles.filter(({ isDisabled, value }) => {
    if (profileToExclude) {
      return value !== profileToExclude && !isDisabled;
    }

    return !isDisabled;
  });

  return _.isEmpty(profileChoices);
};

export const shouldSaveChangesToBackEnd = ({ account, profiles }) =>
  subscriberActivated(account) &&
  getSavedProfiles({ profiles }).keys.length !== 0;

export const getVisitorsGroupId = ({ profiles }) => {
  const visitorsGroup = _.find(profiles.saved.list, {
    name: ProfileGroups.Visitors
  });
  return visitorsGroup ? visitorsGroup.id : "";
};
