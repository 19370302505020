import React, { Fragment, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Icon } from "../../../components/icon/Icon";
import { fetchNewDevices } from "../../../store/devices/devices.actions";
import { Table } from "../../../components/table/Table";
import { Button } from "../../../components/button/Button";
import { getOnlineDevicesForTable } from "../../../store/devices/devices.selectors";
import { useGetLastSeen } from "../../../components/devices/useGetLastSeen";
import { DeviceWithType } from "../../../components/devices/DeviceWithType";
import { Manufacturer } from "../../../components/manufacturer/Manufacturer";
import { Mobile, NotMobile } from "../../../hocs/Responsive";
import { TableExpandableCard } from "../../../components/table/components/TableExpandableCard";
import {
  singleDeviceIdSort,
  timeSort
} from "../../../components/table/helpers/sorting.helper";
import { DiscoveredDevicesDropdownActions } from "./DiscoveredDevicesDropdownActions";

const COLUMNS = [
  {
    Header: <FormattedMessage id={"device_id"} />,
    accessor: "id",
    Cell: ({
      row: {
        original: { type, id }
      }
    }) => {
      return <DeviceWithType type={type} identifier={id} />;
    },
    sortType: singleDeviceIdSort
  },
  {
    Header: <FormattedMessage id={"manufacturer"} />,
    accessor: "manufacturer",
    Cell: ({
      row: {
        original: { manufacturer }
      }
    }) => <Manufacturer manufacturer={manufacturer} />
  },
  {
    Header: <FormattedMessage id={"last_seen"} />,
    accessor: "lastSeen",
    sortType: timeSort,
    cellClassName: "color-secondary",
    Cell: ({
      row: {
        original: { lastSeen }
      }
    }) => <div>{lastSeen || "—"}</div>
  },
  {
    id: "actions",
    sortable: false,
    headerClassName: "table__cell--small-cell",
    Header: <FormattedMessage id={"actions"} />,
    cellClassName: "table__cell--center table__cell--overflow",
    Cell: ({
      row: {
        original: { type, id, manufacturer }
      }
    }) => (
      <DiscoveredDevicesDropdownActions
        device={{ type, manufacturer, identifiers: [id] }}
      />
    )
  }
];

const COLUMNS_MOBILE = [
  {
    accessor: "id",
    cellClassName: "table__cell--overflow",
    SortingLabel: <FormattedMessage id={"device_id"} />,
    Cell: ({
      row: {
        original: { type, manufacturer, id, lastSeen }
      }
    }) => (
      <TableCard
        type={type}
        manufacturer={manufacturer}
        id={id}
        lastSeen={lastSeen}
      />
    ),
    sortType: singleDeviceIdSort
  },
  {
    accessor: "manufacturer",
    SortingLabel: <FormattedMessage id={"manufacturer"} />
  },
  {
    accessor: "lastSeen",
    sortType: timeSort,
    SortingLabel: <FormattedMessage id={"last_seen"} />
  }
];

const SEARCH_KEYS = ["id", "lastSeen", "manufacturer"];

const initialSort = [{ id: "lastSeen", desc: true }];

const SUB_HEADER = <FormattedMessage id={"refresh_to_see_devices"} />;

export const DiscoveredDevicesTable = () => {
  const getLastSeen = useGetLastSeen();
  const devices = useSelector(getOnlineDevicesForTable, _.isEqual);

  const updatedDevices = useMemo(
      () =>
          _.map(devices, (device) => ({
            ...device,
            unix: device.lastSeen,
            lastSeen: getLastSeen(device.lastSeen),
          })),
      [devices, getLastSeen]
  );

  return (
    <Fragment>
      <DiscoveredDevicesInfo />
      <Mobile>
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          showMobileSort
          visibleHeader={false}
          data={updatedDevices}
          columns={COLUMNS_MOBILE}
          hiddenColumns={["manufacturer", "lastSeen"]}
          defaultSorted={initialSort}
          subHeader={SUB_HEADER}
          searchAriaLabelMessage="aria_search_for_device"
        />
      </Mobile>
      <NotMobile>
        <Table
          searchable
          searchKeys={SEARCH_KEYS}
          showPagination
          columns={COLUMNS}
          data={updatedDevices}
          defaultSorted={initialSort}
          subHeader={SUB_HEADER}
          searchAriaLabelMessage="aria_search_for_device"
        />
      </NotMobile>
    </Fragment>
  );
};

export const DiscoveredDevicesInfo = () => {
  const dispatch = useDispatch();

  return (
    <div className={"devices__refresh"}>
      <Button
        isOutline
        size={"large"}
        onClick={() => dispatch(fetchNewDevices())}
      >
        <Icon icon="far fa-sync-alt color-secondary" />
        <span>
          <FormattedMessage id="refresh" />
        </span>
      </Button>
    </div>
  );
};

const TableCard = ({ type, id, manufacturer, lastSeen }) => {
  return (
    <TableExpandableCard
      showExpand={false}
      content={
        <Fragment>
          <DeviceWithType type={type} identifier={id} />
          {manufacturer && <div>{manufacturer}</div>}
          {lastSeen && <div className="color-secondary">{lastSeen}</div>}
        </Fragment>
      }
      aside={
        <DiscoveredDevicesDropdownActions
          device={{ type, manufacturer, identifiers: [id] }}
        />
      }
    />
  );
};
