import { uuid } from "@sportal/lib";
import _ from "lodash";

import { LoadingState } from "../../shared/withLoadableSlice.types";
import { SBDeviceDetails, SBLogicalDevice } from "../devices.types";
import { withLoadingState } from "../../shared";
import {
  EDIT_DEVICE_SUCCESS,
  EDIT_ROAMING_DEVICE_SUCCESS,
  FETCH_LOGICAL_DEVICES,
  FETCH_LOGICAL_DEVICES_FAILURE,
  FETCH_LOGICAL_DEVICES_SUCCESS,
  LogicalDevicesActionTypes,
  MERGE_DEVICE_SUCCESS,
  REMOVE_DEVICE_SUCCESS,
  REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS,
  SAVE_DEVICE_SUCCESS,
  UNMERGE_DEVICE_SUCCESS,
} from "./logicalDevices.types";
import { LogicalDevice } from "@sportal/api";
import {
  REMOVE_PROFILE_SUCCESS,
  RENAME_PROFILE_SUCCESS,
} from "../../profiles/profiles.types";

export interface LogicalDevicesReducerState extends LoadingState {
  entities: Record<string, SBLogicalDevice>;
  keys: string[];
  detailsByIdentifier: Record<string, SBDeviceDetails>;
}

export const initialState: LogicalDevicesReducerState = {
  isLoading: false,
  success: false,
  error: null,
  entities: {},
  keys: [],
  detailsByIdentifier: {},
};

const splitDetailsFromDevice = (
  devices: (LogicalDevice & { logicalDeviceId?: string })[]
): {
  entities: Record<string, SBLogicalDevice>;
  detailsByIdentifier: Record<string, SBDeviceDetails>;
  keys: string[];
} => {
  const logicalDevices: Record<string, SBLogicalDevice> = {};
  const deviceDetailsMap: Record<string, SBDeviceDetails> = {};

  devices.forEach((device) => {
    const id: string = device.logicalDeviceId || uuid();

    // @ts-ignore
    logicalDevices[id] = {
      ..._.omit(device, ["deviceDetails"]),
      logicalDeviceId: id,
    };

    device.identifiers.forEach((identifier) => {
      deviceDetailsMap[identifier] = {
        // @ts-ignore
        ..._.find(device.deviceDetails, { identifier }),
        logicalDeviceId: id,
      };
    });
  });

  return {
    entities: logicalDevices,
    keys: _.keys(logicalDevices),
    detailsByIdentifier: deviceDetailsMap,
  };
};

const reducer = (
  state = initialState,
  action: LogicalDevicesActionTypes
): LogicalDevicesReducerState => {
  switch (action.type) {
    case FETCH_LOGICAL_DEVICES_SUCCESS: {
      return {
        ...state,
        ...splitDetailsFromDevice(action.payload.content),
      };
    }
    case SAVE_DEVICE_SUCCESS: {
      const formattedDevice = splitDetailsFromDevice([action.payload]);

      return {
        ...state,
        entities: { ...state.entities, ...formattedDevice.entities },
        keys: [...state.keys, ...formattedDevice.keys],
        detailsByIdentifier: {
          ...state.detailsByIdentifier,
          ...formattedDevice.detailsByIdentifier,
        },
      };
    }
    case EDIT_DEVICE_SUCCESS: {
      const oldLogicalDeviceId = action.payload.device.logicalDeviceId;
      const formattedDevice = splitDetailsFromDevice([action.payload.device]);
      const editedDevice = Object.values(formattedDevice.entities)[0];

      return {
        ...state,
        entities: {
          ...state.entities,
          [oldLogicalDeviceId]: editedDevice,
        },
      };
    }
    case MERGE_DEVICE_SUCCESS: {
      const { deviceToUpdate, deviceToRemoveId } = action.payload;
      const deviceToUpdateId = deviceToUpdate.logicalDeviceId;

      const formattedDevice = splitDetailsFromDevice([deviceToUpdate]);
      const device = Object.values(formattedDevice.entities)[0];
      const newEntities = {
        ...state.entities,
        [deviceToUpdateId]: device,
      };

      return {
        ...state,
        entities: deviceToRemoveId
          ? _.omit(newEntities, deviceToRemoveId)
          : newEntities,
        keys: deviceToRemoveId
          ? _.without(state.keys, deviceToRemoveId)
          : state.keys,
        detailsByIdentifier: {
          ...state.detailsByIdentifier,
          ...formattedDevice.detailsByIdentifier,
        },
      };
    }
    case UNMERGE_DEVICE_SUCCESS: {
      const { deviceToUpdate, deviceToAdd } = action.payload;
      const { logicalDeviceId: deviceToUpdateId } = deviceToUpdate;
      const formattedDevices = splitDetailsFromDevice([
        deviceToUpdate,
        deviceToAdd,
      ]);
      const [formattedDeviceToUpdate, formattedDeviceToAdd] = Object.values(
        formattedDevices.entities
      );

      return {
        ...state,
        entities: {
          ...state.entities,
          [deviceToUpdateId]: formattedDeviceToUpdate,
          [formattedDeviceToAdd.logicalDeviceId]: formattedDeviceToAdd,
        },
        keys: [...state.keys, formattedDeviceToAdd.logicalDeviceId],
        detailsByIdentifier: {
          ...state.detailsByIdentifier,
          ...formattedDevices.detailsByIdentifier,
        },
      };
    }
    case REMOVE_DEVICE_SUCCESS: {
      const removedDeviceId = action.payload;
      const { identifiers } = state.entities[removedDeviceId];

      return {
        ...state,
        entities: _.omit(state.entities, removedDeviceId),
        keys: _.without(state.keys, removedDeviceId),
        detailsByIdentifier: _.omit(state.detailsByIdentifier, identifiers),
      };
    }
    case EDIT_ROAMING_DEVICE_SUCCESS: {
      const { identifier, changes } = action.payload;
      const deviceDetails = state.detailsByIdentifier[identifier];
      const { logicalDeviceId } = deviceDetails;

      return {
        ...state,
        entities: changes.name
          ? {
              ...state.entities,
              [logicalDeviceId]: {
                ...state.entities[logicalDeviceId],
                name: changes.name,
              },
            }
          : state.entities,
        detailsByIdentifier: {
          ...state.detailsByIdentifier,
          [identifier]: {
            ...deviceDetails,
            ...changes,
          },
        },
      };
    }
    case REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS: {
      const identifier = action.payload;
      const { logicalDeviceId } = state.detailsByIdentifier[identifier];

      return {
        ...state,
        entities: _.omit(state.entities, logicalDeviceId),
        keys: _.without(state.keys, logicalDeviceId),
        detailsByIdentifier: _.omit(state.detailsByIdentifier, identifier),
      };
    }
    case RENAME_PROFILE_SUCCESS:
    case REMOVE_PROFILE_SUCCESS: {
      const { currentName, newName } = action.payload;

      return {
        ...state,
        entities: _.mapValues(state.entities, (device) => {
          if (device.profile !== currentName) return device;

          return {
            ...device,
            profile: newName,
          };
        }),
      };
    }
    default: {
      return state;
    }
  }
};

export const logicalDevicesReducer = withLoadingState({
  loadActionType: FETCH_LOGICAL_DEVICES,
  successActionType: FETCH_LOGICAL_DEVICES_SUCCESS,
  failureActionType: FETCH_LOGICAL_DEVICES_FAILURE,
})(reducer);
