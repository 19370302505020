import _ from "lodash";
import React from "react";
import {FormattedMessage} from "react-intl";

import {
  isFailure,
  LogicalDevice,
  LogicalDevicesData,
  LogicalDeviceUpdate,
  SpsonDeviceUpdateAttributes,
} from "@sportal/api";
import {
  EDIT_DEVICE_FAILURE,
  EDIT_DEVICE_SUCCESS,
  EDIT_ROAMING_DEVICE_FAILURE,
  EDIT_ROAMING_DEVICE_SUCCESS,
  FETCH_LOGICAL_DEVICES,
  FETCH_LOGICAL_DEVICES_FAILURE,
  FETCH_LOGICAL_DEVICES_SUCCESS,
  FETCH_ROAMING_LIMIT_FAILURE,
  FETCH_ROAMING_LIMIT_SUCCESS,
  LogicalDevicesActionTypes,
  MERGE_DEVICE_FAILURE,
  MERGE_DEVICE_SUCCESS,
  REMOVE_DEVICE_FAILURE,
  REMOVE_DEVICE_SUCCESS,
  REVOKE_ROAMING_DEVICE_ACCESS_FAILURE,
  REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS,
  SAVE_DEVICE_FAILURE,
  SAVE_DEVICE_SUCCESS,
  UNMERGE_DEVICE_FAILURE,
  UNMERGE_DEVICE_SUCCESS,
} from "./logicalDevices.types";
import { SBThunkAction } from "../../redux.types";
import { shouldLoad } from "../../shared";

import { getSubscriberId } from "../../info/info.selectors";
import { getRoamingLimit } from "./logicalDevices.selectors";
import {
  getLogicalDevices,
  getLogicalDevicesEntities,
} from "./logicalDevices.selectors";
import { isDeviceNameBusy } from "../../../helpers/validation.helper";
import {
  getIdentifiers,
  getNewDeviceName,
} from "../../../helpers/devices.helpers";
import { SBLogicalDevice } from "../devices.types";
import Notificator from "../../../components/notification/notification.actions";
import {AddNotificationAction} from "../../../components/notification/notification.types";

export const fetchLogicalDevices =
    (): SBThunkAction<
        void,
        LogicalDevicesActionTypes | AddNotificationAction
    > =>
        async (dispatch, getState, { api }) => {
          const state = getState();
          const logicalDevices = getLogicalDevices(state);

          if (!shouldLoad(logicalDevices)) return;

          dispatch({ type: FETCH_LOGICAL_DEVICES });
          const subscriberId = getSubscriberId(state);
          const result = await api.ssm.logicalDevices.get(subscriberId);

          if (isFailure(result)) {
            dispatch(Notificator.error(<FormattedMessage id={"error_failed_to_load_devices"}/>));
            dispatch(fetchLogicalDevicesFailure(result.error));
            return;
          }

          dispatch(fetchLogicalDevicesSuccess(result.data));
        };

export const fetchLogicalDevicesSuccess = (
    payload: LogicalDevicesData
): LogicalDevicesActionTypes => ({
  type: FETCH_LOGICAL_DEVICES_SUCCESS,
  payload,
});

const fetchLogicalDevicesFailure = (
    error: object & { code: number | string }
): LogicalDevicesActionTypes => ({
  type: FETCH_LOGICAL_DEVICES_FAILURE,
  payload: error,
});

interface CreateSaveDeviceConfig {
  createSuccessNotification: () => AddNotificationAction;
  createErrorNotification: () => AddNotificationAction;
}

export const createSaveDeviceActionCreator =
    ({
       createSuccessNotification,
       createErrorNotification,
     }: CreateSaveDeviceConfig) =>
        (
            device: LogicalDeviceUpdate
        ): SBThunkAction<void, LogicalDevicesActionTypes> =>
            async (dispatch, getState, { api }) => {
              const state = getState();
              const devices = getLogicalDevicesEntities(state);
              const deviceName = _.trim(device.name);

              const subscriberId = getSubscriberId(state);
              const result = await api.ssm.logicalDevices.create(subscriberId, {
                id: device.identifiers[0],
                name: isDeviceNameBusy(devices, deviceName)
                    ? getNewDeviceName(devices, deviceName)
                    : deviceName,
                profile: device.profile,
              });

              if (isFailure(result)) {
                dispatch(createErrorNotification());
                dispatch(saveDeviceFailure(result.error));
                return;
              }

              dispatch(createSuccessNotification());
              dispatch(saveDeviceSuccess(result.data));
            };

export const saveDeviceSuccess = (
    device: LogicalDevice
): LogicalDevicesActionTypes => ({
  type: SAVE_DEVICE_SUCCESS,
  payload: device,
});

const saveDeviceFailure = (
    error: object & { code: number | string }
): LogicalDevicesActionTypes => ({
  type: SAVE_DEVICE_FAILURE,
  payload: error,
});

export const saveDevice = createSaveDeviceActionCreator({
  createSuccessNotification: () => Notificator.success(<FormattedMessage id={"device_added"} />),
  createErrorNotification: () => Notificator.error(<FormattedMessage id={"cant_add_device"} />),
});

export const saveDeviceToBlock = createSaveDeviceActionCreator({
  createSuccessNotification: () => Notificator.success(<FormattedMessage id={"device_blocked"} />),
  createErrorNotification: () => Notificator.error(<FormattedMessage id={"device_was_not_blocked"} />),
});

interface CreateEditDeviceConfig {
  createSuccessNotification: () => AddNotificationAction;
  createErrorNotification: () => AddNotificationAction;
}

export const createEditDeviceActionCreator =
    ({
       createSuccessNotification,
       createErrorNotification,
     }: CreateEditDeviceConfig) =>
        (
            changes: LogicalDeviceUpdate,
            oldName: string = changes.name
        ): SBThunkAction<void, LogicalDevicesActionTypes> =>
            async (dispatch, getState, { api }) => {
              const state = getState();
              const subscriberId = getSubscriberId(state);
              const oldDevice = _.find(getLogicalDevicesEntities(state), {
                name: oldName,
              });

              const result = await api.ssm.logicalDevices.update(
                  subscriberId,
                  { name: oldName },
                  {
                    profile: changes.profile || oldDevice.profile,
                    name: changes.name || oldDevice.name,
                    identifiers: changes.identifiers || oldDevice.identifiers,
                  }
              );

              if (isFailure(result)) {
                dispatch(createErrorNotification());
                dispatch(editDeviceFailure(result.error));
                return;
              }

              dispatch(createSuccessNotification());
              dispatch(
                  editDeviceSuccess({
                    device: { ...result.data, logicalDeviceId: oldDevice.logicalDeviceId },
                  })
              );
            };

export const editDeviceSuccess = (payload: {
  device: LogicalDevice & { logicalDeviceId: string };
}): LogicalDevicesActionTypes => ({
  type: EDIT_DEVICE_SUCCESS,
  payload,
});

const editDeviceFailure = (
    error: object & { code: number | string }
): LogicalDevicesActionTypes => ({
  type: EDIT_DEVICE_FAILURE,
  payload: error,
});

export const editDevice = createEditDeviceActionCreator({
  createSuccessNotification: () => Notificator.success(<FormattedMessage id={"device_changed"} />),
  createErrorNotification: () => Notificator.error(<FormattedMessage id={"couldnt_save_changes"} />),
});

export const editDeviceToBlock = createEditDeviceActionCreator({
  createSuccessNotification: () => Notificator.success(<FormattedMessage id={"device_blocked"} />),
  createErrorNotification: () => Notificator.error(<FormattedMessage id={"device_was_not_blocked"} />),
});

export const blockDevice =
    (
        device: LogicalDeviceUpdate
    ): SBThunkAction<void, LogicalDevicesActionTypes> =>
        (dispatch, getState) => {
          const devices = getLogicalDevicesEntities(getState());

          const isDeviceSaved = _.includes(
              getIdentifiers(Object.values(devices)),
              device.identifiers[0]
          );
          const getSavedDevice = () => _.find(devices, (d) => d.name === device.name);

          const savedDevice = isDeviceSaved ? getSavedDevice() : null;
          return savedDevice
              ? dispatch(
                  editDeviceToBlock({ ...device, identifiers: savedDevice.identifiers })
              )
              : dispatch(saveDeviceToBlock(device));
        };

export const mergeDevice =
    (
        device: SBLogicalDevice,
        id: string
    ): SBThunkAction<void, LogicalDevicesActionTypes | AddNotificationAction> =>
        async (dispatch, getState, { api }) => {
          const state = getState();
          const subscriberId = getSubscriberId(state);
          const deviceToRemove = _.find(getLogicalDevicesEntities(state), (device) =>
              _.includes(device.identifiers, id)
          );

          const result = await api.ssm.logicalDevices.update(
              subscriberId,
              { name: device.name },
              {
                profile: device.profile,
                name: device.name,
                identifiers: [...device.identifiers, id],
              }
          );

          if (isFailure(result)) {
            dispatch(Notificator.error(<FormattedMessage id={"couldnt_save_changes"} />));
            dispatch(mergeDeviceFailure(result.error));
            return;
          }

          dispatch(Notificator.success(<FormattedMessage id={"device_changed"} />));
          dispatch(
              mergeDeviceSuccess({
                deviceToUpdate: {
                  ...result.data,
                  logicalDeviceId: device.logicalDeviceId,
                },
                deviceToRemoveId: deviceToRemove && deviceToRemove.logicalDeviceId,
              })
          );
        };

export const mergeDeviceSuccess = (payload: {
  deviceToUpdate: SBLogicalDevice;
  deviceToRemoveId?: string;
}): LogicalDevicesActionTypes => ({
  type: MERGE_DEVICE_SUCCESS,
  payload,
});

const mergeDeviceFailure = (
    error: object & { code: number | string }
): LogicalDevicesActionTypes => ({
  type: MERGE_DEVICE_FAILURE,
  payload: error,
});

export const unmergeDevice =
    (
        device: SBLogicalDevice,
        idToUnmerge: string
    ): SBThunkAction<void, LogicalDevicesActionTypes | AddNotificationAction> =>
        async (dispatch, getState, { api }) => {
          const state = getState();
          const subscriberId = getSubscriberId(state);

          const updateDeviceResult = await api.ssm.logicalDevices.update(
              subscriberId,
              { name: device.name },
              {
                profile: device.profile,
                name: device.name,
                identifiers: _.without(device.identifiers, idToUnmerge),
              }
          );

          if (isFailure(updateDeviceResult)) {
            dispatch(unmergeDeviceFailure(updateDeviceResult.error));
            return;
          }

          const savedDevices = getLogicalDevicesEntities(state);
          const createNewDeviceResult = await api.ssm.logicalDevices.create(
              subscriberId,
              {
                name: isDeviceNameBusy(savedDevices, idToUnmerge)
                    ? getNewDeviceName(savedDevices, idToUnmerge)
                    : idToUnmerge,
                id: idToUnmerge,
                profile: device.profile,
              }
          );

          if (isFailure(createNewDeviceResult)) {
            dispatch(unmergeDeviceFailure(createNewDeviceResult.error));
          } else {
            dispatch(Notificator.success(<FormattedMessage id={"changes_saved"} />));
            dispatch(
                unmergeDeviceSuccess({
                  deviceToUpdate: {
                    ...updateDeviceResult.data,
                    logicalDeviceId: device.logicalDeviceId,
                  },
                  deviceToAdd: createNewDeviceResult.data,
                })
            );
          }
        };

export const unmergeDeviceSuccess = (payload: {
  deviceToUpdate: SBLogicalDevice;
  deviceToAdd: LogicalDevice;
}): LogicalDevicesActionTypes => ({
  type: UNMERGE_DEVICE_SUCCESS,
  payload,
});

const unmergeDeviceFailure =
    (error: object & { code: number | string }) => (dispatch) => {
      dispatch(Notificator.error(<FormattedMessage id={"couldnt_save_changes"} />));
      dispatch({
        type: UNMERGE_DEVICE_FAILURE,
        payload: error,
      });
    };

export const removeDevice =
    (
        deviceName: string,
        logicalDeviceId: string
    ): SBThunkAction<void, LogicalDevicesActionTypes | AddNotificationAction> =>
        async (dispatch, getState, { api }) => {
          const subscriberId = getSubscriberId(getState());

          const result = await api.ssm.logicalDevices.remove(subscriberId, {
            name: deviceName,
          });

          if (isFailure(result)) {
            dispatch(Notificator.error(<FormattedMessage id={"couldnt_save_changes"} />));
            dispatch(removeDeviceFailure(result.error));
            return;
          }

          dispatch(removeDeviceSuccess(logicalDeviceId));
        };

export const removeDeviceSuccess = (
    logicalDeviceId: string
): LogicalDevicesActionTypes => ({
  type: REMOVE_DEVICE_SUCCESS,
  payload: logicalDeviceId,
});

const removeDeviceFailure = (
    error: object & { code: number | string }
): LogicalDevicesActionTypes => ({
  type: REMOVE_DEVICE_FAILURE,
  payload: error,
});

export const editRoamingDevice =
    (
        identifier: string,
        changes: SpsonDeviceUpdateAttributes
    ): SBThunkAction<void, LogicalDevicesActionTypes | AddNotificationAction> =>
        async (dispatch, getState, { api }) => {
          const subscriberId = getSubscriberId(getState());

          const result = await api.ssm.logicalDevices.updateSpsonDevice(
              subscriberId,
              identifier,
              changes
          );

          if (isFailure(result)) {
            dispatch(Notificator.error(<FormattedMessage id={"could_not_update_roaming_device"} />));
            dispatch(editRoamingDeviceFailure(result.error));
            return;
          }

          dispatch(Notificator.success(<FormattedMessage id={"successfully_saved"} />));
          dispatch(editRoamingDeviceSuccess(identifier, changes));
        };

export const editRoamingDeviceSuccess = (
    identifier: string,
    changes: SpsonDeviceUpdateAttributes
): LogicalDevicesActionTypes => ({
  type: EDIT_ROAMING_DEVICE_SUCCESS,
  payload: { identifier, changes },
});

const editRoamingDeviceFailure = (
    error: object & { code: number | string }
): LogicalDevicesActionTypes => ({
  type: EDIT_ROAMING_DEVICE_FAILURE,
  payload: error,
});

export const revokeRoamingDeviceAccess =
    (
        identifier: string
    ): SBThunkAction<void, LogicalDevicesActionTypes | AddNotificationAction> =>
        async (dispatch, getState, { api }) => {
          const subscriberId = getSubscriberId(getState());

          const result = await api.ssm.logicalDevices.revoke(
              subscriberId,
              identifier
          );

          if (isFailure(result)) {
            dispatch(Notificator.error(<FormattedMessage id={"could_not_revoke_device_access"} />));
            dispatch(revokeRoamingDeviceFailure(result.error));
            return;
          }

          dispatch(revokeRoamingDeviceSuccess(identifier));
        };

export const revokeRoamingDeviceSuccess = (
    identifier: string
): LogicalDevicesActionTypes => ({
  type: REVOKE_ROAMING_DEVICE_ACCESS_SUCCESS,
  payload: identifier,
});

const revokeRoamingDeviceFailure = (
    error: object & { code: number | string }
): LogicalDevicesActionTypes => ({
  type: REVOKE_ROAMING_DEVICE_ACCESS_FAILURE,
  payload: error,
});

export const fetchRoamingLimit =
    (): SBThunkAction<
        void,
        LogicalDevicesActionTypes | AddNotificationAction
    > =>
        async (dispatch, getState, { api }) => {
          const state = getState();
          const subscriberId = getSubscriberId(state);
          const currentLimit = getRoamingLimit(state);

          if (currentLimit) return;

          const result = await api.ssm.logicalDevices.getSpsonLimit(subscriberId);

          if (isFailure(result)) {
            dispatch(Notificator.error(<FormattedMessage id={"fetch_roaming_limit_error"} />));
            dispatch(fetchRoamingLimitFailure(result.error));
            return;
          }

          dispatch(fetchRoamingLimitSuccess(result.data));
        };

export const fetchRoamingLimitSuccess = (
    limit: number
): LogicalDevicesActionTypes => ({
  type: FETCH_ROAMING_LIMIT_SUCCESS,
  payload: limit,
});

const fetchRoamingLimitFailure = (
    error: object & { code: number | string }
): LogicalDevicesActionTypes => ({
  type: FETCH_ROAMING_LIMIT_FAILURE,
  payload: error,
});
