import React from "react";

import { useLoadableSlice } from "../../store/shared";
import { getDisplayName } from "../../utils/getDisplayName";
import {fetchLogicalDevices, getLogicalDevices} from "../../store/devices/logicalDevices";
import {fetchLines, getLines} from "../../store/devices/lines";
import {fetchDiscovered, getDiscoveredDevices} from "../../store/devices/discovered";
import {fetchRequestsWithName, getRequestsWithName} from "../../store/devices/requestsWithName";

export function withSettingDevicesGuard(Component) {
  function SettingDevicesGuard() {
    const logicalSlice = useLoadableSlice(
      fetchLogicalDevices(),
      getLogicalDevices
    );
    const linesSlice = useLoadableSlice(fetchLines(), getLines);
    const discoveredSlice = useLoadableSlice(
      fetchDiscovered(true),
      getDiscoveredDevices
    );
    const withNameSlice = useLoadableSlice(
      fetchRequestsWithName(true),
      getRequestsWithName
    );

    if (
      [logicalSlice, linesSlice, discoveredSlice, withNameSlice].includes(null)
    ) {
      return null;
    }

    return <Component />;
  }

  SettingDevicesGuard.displayName = `withSettingDevicesGuard(${getDisplayName(
    Component
  )})`;
  return SettingDevicesGuard;
}
