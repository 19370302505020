import _ from "lodash";
import React, { Component, Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";

import {LogicalDeviceDetailsTypes} from "@sportal/api";

import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog
} from "../../../modal";
import { DeviceNameInput } from "../../commonComponents";
import { ProfileGroups } from "../../../../store/profiles/fixedProfiles";

import "./BlockDeviceDialog.scss";
import "../../commonStyles.scss";
import {
  isAddressInList
} from "../../../../helpers/devices.helpers";

class BlockDeviceDialog extends Component {
  constructor(props) {
    super(props);

    this.requestedDevice = this.getRequestedDevice();
    this.deviceInfo = this.getDeviceInfo();

    this.state = {
      name: this.props.device.name || this.getDeviceName(),
      errorName: "",
      profile: ProfileGroups.Blocked
    };
  }

  render() {
    const { modal, devices } = this.props;

    return (
      <ModalDialog
        className={"block-device-modal"}
        submitHandler={this.onSubmit}
        rejectHandler={() => modal.reject()}
        disabled={this.isSubmitDisabled()}
      >
        <CommonModalHeader>
          <FormattedMessage id={"block_device"} />
        </CommonModalHeader>
        <ModalDialog.Body>
          {this.requestedDevice && (
            <span className={"color-danger"}>
              <FormattedMessage id={"you_have_request"} />{" "}
            </span>
          )}
          <FormattedMessage id={"if_continue"} />
          <span className="device-name">{this.deviceInfo}</span>
          <FormattedMessage id={"wont_able_connect"} />
          {this.showInputForName() && (
            <DeviceNameInput
              name={this.state.name}
              handleChange={(name, error) =>
                this.handleInputChange(name, error)
              }
              devices={devices}
              error={this.state.errorName}
              label={
                <Fragment>
                  <FormattedMessage id={"device_name"} /> (
                  <FormattedMessage id={"optional"} />)
                </Fragment>
              }
              allowEmptyName
              autoFocus
            />
          )}
        </ModalDialog.Body>
        <CommonModalFooter
          submitMessage={<FormattedMessage id="block" />}
          submitButtonType="destructive"
        />
      </ModalDialog>
    );
  }

  handleInputChange(name, error) {
    if (name === this.state.name) return;
    if (name.length > 0 && name.trim() === "") {
      this.setState({ name: "" });
      return;
    }

    this.setState({ name });
    this.setError(error);
  }

  getDeviceInfo() {
    const { device } = this.props;
    const identificator = device.name
      ? device.name
      : this.requestedDevice
      ? this.requestedDevice.name
      : device.identifiers[0];

    let message = ` ${identificator}`;
    message += device.manufacturer ? `, ${device.manufacturer} ` : " ";

    return message;
  }

  getDeviceName() {
    return this.requestedDevice ? this.requestedDevice.name : "";
  }

  isMobileDevice() {
    return this.props.device.type === LogicalDeviceDetailsTypes.Line;
  }

  getRequestedDevice() {
    return _.find(this.props.requested, d =>
      isAddressInList(this.props.device.identifiers, d.id)
    );
  }

  showInputForName() {
    return (
      !this.props.device.name && !this.requestedDevice && !this.isMobileDevice()
    );
  }

  setError(error) {
    if (error) {
      this.setState({
        errorName: this.props.intl.formatMessage({ id: error })
      });
    } else {
      this.setState({ errorName: "" });
    }
  }

  onSubmit = () => {
    if (this.isSubmitDisabled()) return;

    const {
      modal,
      device: { identifiers }
    } = this.props;

    return modal.resolve({
      identifiers,
      name: this.state.name || identifiers[0],
      profile: this.state.profile
    });
  };

  isSubmitDisabled() {
    return !!this.state.errorName;
  }
}

BlockDeviceDialog.propTypes = {
  device: PropTypes.object
};

export default injectIntl(BlockDeviceDialog);
