import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import { useForm } from "@sportal/cdk/form/Form";
import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog,
  ModalFormControl
} from "../../../modal";

import { DeviceNameInput, UserFullNameInput } from "../../commonComponents";
import { useIntlTranslate } from "../../../../hooks";

export const EditRoamingDeviceDialog = ({ modal, device, devices }) => {
  const translate = useIntlTranslate();
  const formProps = useForm({
    name: device.name,
    username: device.fullName
  });

  const close = () => modal.reject();
  const submit = () =>
    modal.resolve(_.mapValues(formProps.getChanges(), _.trim));

  const createInputChangeHandler = name => (value, error) => {
    formProps.set(name, value);
    formProps.setError(name, error);
  };

  return (
    <ModalDialog
      submitHandler={submit}
      rejectHandler={close}
      disabled={!formProps.didFormChange() || formProps.hasErrors()}
    >
      <CommonModalHeader>
        <FormattedMessage id="edit_info" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <ModalFormControl>
          <DeviceNameInput
            name={formProps.values.name}
            error={formProps.errors.name && translate(formProps.errors.name)}
            devices={_.keyBy(devices, "logicalDeviceId")}
            handleChange={createInputChangeHandler("name")}
            label={<FormattedMessage id="device_name" />}
            autoFocus
          />
        </ModalFormControl>
        <ModalFormControl>
          <UserFullNameInput
            value={formProps.values.username}
            error={
              formProps.errors.username && translate(formProps.errors.username)
            }
            handleChange={createInputChangeHandler("username")}
            label={<FormattedMessage id="full_name" />}
            autoFocus
          />
        </ModalFormControl>
      </ModalDialog.Body>
      <CommonModalFooter submitMessage={<FormattedMessage id="save" />} />
    </ModalDialog>
  );
};
