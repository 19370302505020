import React, { Fragment } from "react";

import {
  useAssignDeviceMenuItem,
  useMergeDeviceMenuItem,
  useBlockDeviceMenuItem
} from "../../../../components/devices/hooks";
import { DeviceDropdownMenu } from "../../../../components/devices/DeviceDropdownMenu";
import { DeviceActionsToggle } from "../../../../components/devices/DeviceActionsDialog";
import { Mobile, NotMobile } from "../../../../hocs/Responsive";

export const OnlineDevicesDropdownActions = ({
  id,
  type,
  manufacturer
}) => {
  const menuItems = [
    useMergeDeviceMenuItem({ id, type }),
    useAssignDeviceMenuItem(
      { identifiers: [id], type },
      "assign_to_group"
    ),
    useBlockDeviceMenuItem({ identifiers: [id], type, manufacturer })
  ];

  return (
    <Fragment>
      <Mobile>
        <DeviceActionsToggle deviceName={id} menuItems={menuItems} />
      </Mobile>
      <NotMobile>
        <DeviceDropdownMenu menuItems={menuItems} />
      </NotMobile>
    </Fragment>
  );
};
