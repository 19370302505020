import { InfectedDeviceServerData } from "@sportal/api";

export const FETCH_INFECTED = "[DEVICES] FETCH_INFECTED";
export const FETCH_INFECTED_SUCCESS = "[DEVICES] FETCH_INFECTED_SUCCESS";
export const FETCH_INFECTED_FAILURE = "[DEVICES] FETCH_INFECTED_FAILURE";

interface FetchInfectedRequestAction {
  type: typeof FETCH_INFECTED;
}

interface FetchInfectedSuccessAction {
  type: typeof FETCH_INFECTED_SUCCESS;
  payload: InfectedDeviceServerData;
}

interface FetchInfectedFailureAction {
  type: typeof FETCH_INFECTED_FAILURE;
  payload: object & { code: number | string };
}

export type InfectedDevicesActionTypes =
  | FetchInfectedRequestAction
  | FetchInfectedSuccessAction
  | FetchInfectedFailureAction;
