import React from "react";

import { TableExpandableCard } from "../../../components/table/components/TableExpandableCard";
import { ProtectionStatus } from "./ProtectionStatus";
import { ClickAbsorber } from "../../../components/clickAbsorber/ClickAbsorber";
import {
  DeviceNameCell,
  DeviceNameCellMobile
} from "../../../components/table";

import "./RoamingDeviceNameCard.scss";

export const RoamingDeviceNameCard = ({ isExpanded, name }) => {
  return (
    <TableExpandableCard
      isExpanded={isExpanded}
      content={<DeviceNameCell name={name} />}
    />
  );
};

export const RoamingDeviceNameMobileCard = ({
  isExpanded,
  name,
  aside,
  protectionStatus,
  lastSeen
}) => (
  <TableExpandableCard
    isExpanded={isExpanded}
    aside={<ClickAbsorber>{aside}</ClickAbsorber>}
    content={
      <div className="roaming-device-name-mobile-card">
        <DeviceNameCellMobile name={name} />
        <ProtectionStatus status={protectionStatus} />
        <span className="color-secondary">{lastSeen || "—"}</span>
      </div>
    }
  />
);
