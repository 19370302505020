import _ from "lodash";
import { isFailure } from "@sportal/api";

import {
  CHECK_CODE_FAILURE,
  RequestsWithCodeActionTypes,
} from "./requestsWithCode.types";
import { SBThunkAction } from "../../redux.types";
import { getSubscriberId } from "../../info/info.selectors";

export const checkCode =
  (code: string): SBThunkAction<void, RequestsWithCodeActionTypes> =>
  async (dispatch, getState, { api }) => {
    const subscriberId = getSubscriberId(getState());
    const result = await api.ssm.deviceRequests.getDevicesWithCode(subscriberId);

    if (isFailure(result)) {
      dispatch(checkCodeFailure(result.error));
      return Promise.reject();
    }

    const matchingDevice = _.find(result.data, { code });

    return Promise.resolve(matchingDevice ? matchingDevice.id : "");
  };

const checkCodeFailure = (error: {
  code: string | number;
}): RequestsWithCodeActionTypes => ({
  type: CHECK_CODE_FAILURE,
  payload: error,
});
