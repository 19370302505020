import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import _ from "lodash";

import { DeviceWithType } from "../../../../components/devices/DeviceWithType";
import { Manufacturer } from "../../../../components/manufacturer/Manufacturer";
import { Table } from "../../../../components/table/Table";
import { TableExpandableCard } from "../../../../components/table/components/TableExpandableCard";
import { Mobile, NotMobile } from "../../../../hocs/Responsive";
import { NoDevices } from "../noDevices/NoDevices";
import { ProfileDevicesDropdownActions } from "./ProfileDevicesDropdownActions";
import { multipleDeviceIdSort } from "../../../../components/table/helpers/sorting.helper";
import {
  DeviceNameCell,
  DeviceNameCellMobile
} from "../../../../components/table";
import {getNotRoamingDevicesByProfile} from "../../../../store/devices/logicalDevices";

const COLUMNS = [
  {
    Header: <FormattedMessage id={"device_name"} />,
    accessor: "name",
    sortable: true,
    Cell: ({ row: { original } }) => {
      const name = _.unescape(original.name); // why do we have this special use-case here with _unescape, but not in other tables?

      return <DeviceNameCell name={name} />;
    }
  },
  {
    Header: <FormattedMessage id={"device_id"} />,
    accessor: "identifiers",
    sortable: true,
    sortType: multipleDeviceIdSort,
    Cell: ({
      row: {
        original: { details }
      }
    }) => (
      <div className={"identifiers-block"}>
        {_.map(details, ({ type, identifier }) => (
          <DeviceWithType
            key={identifier}
            type={type}
            identifier={identifier}
          />
        ))}
      </div>
    )
  },
  {
    Header: <FormattedMessage id={"manufacturer"} />,
    accessor: "manufacturer",
    sortable: true,
    cellClassName: "color-secondary",
    Cell: ({
      row: {
        original: { manufacturer }
      }
    }) => <Manufacturer manufacturer={manufacturer} />
  },
  {
    Header: <FormattedMessage id={"actions"} />,
    id: "actions",
    sortable: false,
    headerClassName: "table__cell--small-cell",
    cellClassName: "table__cell--center table__cell--overflow",
    Cell: ({
      row: {
        original: { identifiers, details, name, profile }
      }
    }) => (
      <ProfileDevicesDropdownActions
        identifiers={identifiers}
        deviceDetails={details}
        name={name}
        profile={profile}
      />
    )
  }
];

const COLUMNS_MOBILE = [
  {
    accessor: "name",
    cellClassName: "table__cell--overflow",
    SortingLabel: <FormattedMessage id={"device_name"} />,
    Cell: ({
      row: {
        original: { name, details, identifiers, profile }
      }
    }) => (
      <TableCard
        name={name}
        details={details}
        identifiers={identifiers}
        profile={profile}
      />
    )
  },
  {
    accessor: "identifiers",
    sortType: multipleDeviceIdSort,
    SortingLabel: <FormattedMessage id={"device_id"} />
  }
];

const SEARCH_KEYS = ["identifiers", "manufacturer", "name"];

export const ProfileNotRoamingDevicesTable = ({ profileName }) => {
  const devices = useSelector(
    getNotRoamingDevicesByProfile(profileName),
    _.isEqual
  );

  const hasDevices = devices.length !== 0;

  if (!hasDevices) {
    return <NoDevices profileName={profileName} />;
  }

  return (
    <Fragment>
      <Fragment>
        <Mobile>
          <Table
            searchable
            searchKeys={SEARCH_KEYS}
            showPagination
            showMobileSort
            visibleHeader={false}
            columns={COLUMNS_MOBILE}
            hiddenColumns={["identifiers"]}
            data={devices}
            searchAriaLabelMessage="aria_search_for_device"
          />
        </Mobile>
        <NotMobile>
          <Table
            searchable
            searchKeys={SEARCH_KEYS}
            showPagination
            columns={COLUMNS}
            data={devices}
            searchAriaLabelMessage="aria_search_for_device"
          />
        </NotMobile>
      </Fragment>
    </Fragment>
  );
};

const TableCard = ({ name, details, identifiers, profile }) => {
  return (
    <TableExpandableCard
      showExpand={false}
      content={
        <Fragment>
          <DeviceNameCellMobile name={name} />
          <div className={"identifiers-block"}>
            {_.map(details, ({ type, identifier }) => (
              <DeviceWithType
                key={identifier}
                type={type}
                identifier={identifier}
              />
            ))}
          </div>
        </Fragment>
      }
      aside={
        <ProfileDevicesDropdownActions
          identifiers={identifiers}
          deviceDetails={details}
          name={name}
          profile={profile}
        />
      }
    />
  );
};
