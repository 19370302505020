// TODO: may be reused in reports
export enum ReportDuration {
  FifteenMinutes = "PT15M",
  OneDay = "P1D",
  OneHour = "PT1H",
}

interface ReportSlice<T> {
  time: number;
  value: T[];
}

// TODO: may be reused in reports
export type ReportData<T> = ReportSlice<T>[];

interface RequestWithNameServerData extends Array<string | number> {
  /** identifier - MAC address or telephone number */
  0: string;
  /** name of device */
  1: string;
  /** timestamp in milliseconds reflects the time when the device was last seen */
  2: number;
}

interface RequestWithCodeServerData extends Array<string | number> {
  /** identifier - MAC address or telephone number */
  0: string;
  /** code - a string suffixed with .bb.nom-proxy */
  1: string;
  /** timestamp in milliseconds reflects the time when the device was last seen */
  2: number;
}

interface DiscoveredDeviceServerData extends Array<string | number> {
  /** identifier - MAC address or telephone number */
  0: string;
  /** timestamp in milliseconds reflects the time when the device was last seen */
  1: number;
}

export type RequestsWithNameServerData = ReportData<RequestWithNameServerData>;
export type RequestsWithCodeServerData = ReportData<RequestWithCodeServerData>;
export type DiscoveredDevicesServerData =
    ReportData<DiscoveredDeviceServerData>;

export interface RequestWithName {
  id: string;
  manufacturer: string;
  name: string;
  lastSeen: number;
}

export interface BasicRequestWithCode {
  id: string;
  code: string;
  lastSeen: number;
}

export interface RequestWithCode extends BasicRequestWithCode {
  manufacturer: string;
}

export interface DiscoveredDevice {
  id: string;
  manufacturer: string;
  lastSeen: number;
}

export interface RequestsCollection {
  withName: RequestWithName[];
  withCode: RequestWithCode[];
  discovered: DiscoveredDevice[];
}

export interface IDeviceRequestsProvider {
  getRequestsWithName: (
      subscriberId: string,
      duration: ReportDuration
  ) => Promise<{ id: string; name: string; lastSeen: number }[]>;
  getRequestsWithCode: (
      subscriberId: string,
      duration: ReportDuration
  ) => Promise<{ id: string; code: string; lastSeen: number }[]>;
  getDiscovered: (
      subscriberId: string,
      duration: ReportDuration
  ) => Promise<{ id: string; lastSeen: number }[]>;
}
