import { HttpService, HttpServiceResponse } from "../../shared";
import { ILinesProvider, LinesServerData } from "./lines.types";

export class LinesProvider implements ILinesProvider {
  constructor(private http: HttpService, private baseUrl: string) {}

  public async get(
    subscriberId: string
  ): Promise<HttpServiceResponse<LinesServerData>> {
    return await this.http.get<LinesServerData>(this.url(subscriberId));
  }

  private url(subscriberId: string): string {
    return `${this.baseUrl}/ssm/account/${subscriberId}/line`;
  }
}
