import React from "react";
import {FormattedMessage} from "react-intl";

import { DiscoveredDevice, isFailure } from "@sportal/api";

import {
  FETCH_DISCOVERED,
  FETCH_DISCOVERED_SUCCESS,
  FETCH_DISCOVERED_FAILURE,
  DiscoveredActionTypes,
} from "./discovered.types";
import { SBThunkAction } from "../../redux.types";
import { getSubscriberId } from "../../info/info.selectors";
import Notificator from "../../../components/notification/notification.actions";
import {AddNotificationAction} from "../../../components/notification/notification.types";

export const fetchDiscovered =
  (
    initialLoad = false
  ): SBThunkAction<void, DiscoveredActionTypes | AddNotificationAction> =>
  async (dispatch, getState, { api }) => {
    if (initialLoad) {
      dispatch({ type: FETCH_DISCOVERED });
    }

    const subscriberId = getSubscriberId(getState());
    const result = await api.ssm.deviceRequests.getDiscovered(subscriberId);

    if (isFailure(result)) {
      dispatch(
          Notificator.error(
              <FormattedMessage id={"error_failed_to_load_manufacturers"} />
          )
      );
      dispatch(fetchDiscoveredFailure(result.error));
      return;
    }

    dispatch(fetchDiscoveredSuccess(result.data));
  };

export const fetchDiscoveredSuccess = (
  discovered: DiscoveredDevice[]
): DiscoveredActionTypes => ({
  type: FETCH_DISCOVERED_SUCCESS,
  payload: discovered,
});

const fetchDiscoveredFailure = (
  error: object & { code: string | number }
): DiscoveredActionTypes => ({
  type: FETCH_DISCOVERED_FAILURE,
  payload: error,
});
