import {
  LogicalDevicesService,
  LogicalDevicesProvider,
  HttpService,
} from "@sportal/api";
import { makeManufacturerService } from "./manufacturerService";

export const makeLogicalDevicesService = (
  http: HttpService,
  baseUrl: string
): LogicalDevicesService => {
  return new LogicalDevicesService(
    new LogicalDevicesProvider(http, baseUrl),
    makeManufacturerService(http, baseUrl),
    false
  );
};
