import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog
} from "../../../../components/modal";
import {getDevicesByProfile} from "../../../../store/devices/logicalDevices";

export const RemoveProfileDialog = ({ modal, profileName }) => {
  const profileDevices = useSelector(
    getDevicesByProfile(profileName),
    _.isEqual
  );

  const hasDevices = profileDevices.length > 0;

  return (
    <ModalDialog
      className="remove-profile-modal"
      submitHandler={() => modal.resolve()}
      rejectHandler={() => modal.reject()}
    >
      <CommonModalHeader>
        <FormattedMessage id="confirm_deletion" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <FormattedMessage
          id="delete_profile_message"
          values={{
            name: <span className="text-highlighted">{profileName}</span>
          }}
        />
        {hasDevices && (
          <Fragment>
            <br />
            <FormattedMessage id="all_devices_in_group_will_be_unassigned" />
          </Fragment>
        )}
      </ModalDialog.Body>
      <CommonModalFooter
        submitMessage={<FormattedMessage id="delete_group" />}
        submitButtonType="destructive"
      />
    </ModalDialog>
  );
};
