import { HttpService, HttpServiceResponse } from "../../shared";
import { IManufacturerProvider, Manufacturer } from "./manufacturer.types";

export class ManufacturerProvider implements IManufacturerProvider {
  private get url() {
    return `${this.baseUrl}/manufacturer`;
  }

  constructor(private http: HttpService, private baseUrl: string) {}

  public get(
    macAddresses: string[]
  ): Promise<HttpServiceResponse<Manufacturer[]>> {
    return this.http.post<Manufacturer[]>(
      this.url,
      macAddresses.map(id => ({ id }))
    );
  }
}
