import { BaseApiService } from "../baseApiService";
import { ListService } from "./listService";
import { SeriesService } from "./seriesService";
import { SettingsService } from "./settingsService";
import { AccountService } from "./accountService";
import { ProtectionService } from "./protectionService";
import { DevicesService } from "./devicesService";
import { ThreatsService } from "./threatsService";
import { ProfileService } from "./profileService";
import { SafetyService } from "./subscriberSafety";
import { ScheduleService } from "./scheduleService";
import { HistoryClearService } from "./historyClearService";
import { ResourcesService } from "./resourcesService";
import {makeLogicalDevicesService} from "./logicalDevicesService";
import {makeInfectedDevicesService} from "./infectedDevicesService";
import {makeLinesService} from "./linesService";
import {makeDeviceRequestsService} from "./deviceRequestsService";

export class SsmService extends BaseApiService {
  get list() {
    return this._list;
  }

  get series() {
    return this._series;
  }

  get settings() {
    return this._settings;
  }

  get account() {
    return this._account;
  }

  get protection() {
    return this._protection;
  }

  get profile() {
    return this._profile;
  }

  get devices() {
    return this._devices;
  }

  get threats() {
    return this._threats;
  }

  get safety() {
    return this._safety;
  }

  get schedules() {
    return this._schedules;
  }

  get history() {
    return this._history;
  }

  get resources() {
    return this._resources;
  }

  get deviceRequests() {
    return this._deviceRequests;
  }

  get lines() {
    return this._lines;
  }

  get infectedDevices() {
    return this._infectedDevices;
  }

  get logicalDevices() {
    return this._logicalDevices;
  }

  constructor(config) {
    super(config);

    const { http, backendUrl } = config;
    this._list = config.list || new ListService(config);
    this._series = config.series || new SeriesService(config);
    this._settings = config.settings || new SettingsService(config);
    this._account = config.account || new AccountService(config);
    this._protection = config.protection || new ProtectionService(config);
    this._profile = config.profile || new ProfileService(config);
    this._devices = config.devices || new DevicesService(config);
    this._threats = config.threats || new ThreatsService(config);
    this._safety = config.safety || new SafetyService(config);
    this._schedules = config.schedules || new ScheduleService(config);
    this._history = config.history || new HistoryClearService(config);
    this._resources = config.resources || new ResourcesService(config);
    this._deviceRequests = makeDeviceRequestsService(http, backendUrl);
    this._lines = makeLinesService(http, backendUrl);
    this._infectedDevices = makeInfectedDevicesService(http, backendUrl);
    this._logicalDevices = makeLogicalDevicesService(http, backendUrl);
  }
}
