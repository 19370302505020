import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog,
  ModalFormControl
} from "../../../modal";

import { IdentifiersStub } from "../../commonComponents";
import { DevicesSelect, ProfilesSelect } from "./Selects";
import { formatProfileNameInClass } from "../../../profiles/FormattedProfileName";

const CLASS_NAME = "merge-device-modal";

class MergeDeviceDialog extends Component {
  constructor(props) {
    super(props);

    this.newAddress = this.getDeviceAddress(props.deviceToMerge);
    this.state = {
      selectedLogicalDeviceId: "",
      profile: "",
    };
  }

  render() {
    const { modal } = this.props;

    return (
      <ModalDialog
        className={CLASS_NAME}
        submitHandler={this.handleSubmit}
        rejectHandler={() => modal.reject()}
        disabled={this.isSubmitDisabled()}
      >
        <CommonModalHeader>
          <FormattedMessage id={"merge_devices"} />
        </CommonModalHeader>
        <ModalDialog.Body>
          <ModalFormControl>
            <IdentifiersStub className={CLASS_NAME} value={this.newAddress} />
          </ModalFormControl>
          <ModalFormControl>
            <ProfilesSelect
              profiles={this.mapProfilesForSelect()}
              selected={this.state.profile}
              onChange={option => this.handleProfileSelect(option)}
            />
          </ModalFormControl>
          <ModalFormControl>
            <DevicesSelect
              devices={this.mapDevicesForSelect()}
              selected={this.state.selectedLogicalDeviceId}
              onChange={option => this.handleDeviceSelect(option)}
            />
          </ModalFormControl>
        </ModalDialog.Body>
        <CommonModalFooter submitMessage={<FormattedMessage id="merge" />} />
      </ModalDialog>
    );
  }

  handleDeviceSelect(selected) {
    if (selected === this.state.selectedLogicalDeviceId) return;

    const device = _.find(this.props.destinationDevices, {
      logicalDeviceId: selected,
    });
    const profile = device ? device.profile : "";

    this.setState({
      selectedLogicalDeviceId: selected,
      profile,
    });
  }

  handleProfileSelect(profile) {
    if (profile === this.state.profile) return;
    this.setState({ profile, selectedLogicalDeviceId: "" });
  }

  mapDevicesForSelect() {
    const filtered = _.filter(this.props.destinationDevices, (device) =>
        this.state.profile ? device.profile === this.state.profile : true
    );
    const mapped = _.map(filtered, (device) => ({
      value: device.logicalDeviceId,
      name: device.name,
    }));

    mapped.unshift({
      value: "",
      name: this.props.intl.formatMessage({ id: "reset_changes" })
    });

    return mapped;
  }

  mapProfilesForSelect() {
    const profiles = _.uniq(_.map(this.props.destinationDevices, "profile"));
    const mapped = _.map(profiles, profile => {
      const savedProfile = _.find(this.props.profiles, { value: profile });

      return {
        value: profile,
        name: formatProfileNameInClass(savedProfile.name, this.props.intl)
      };
    });

    mapped.unshift({
      value: "",
      name: this.props.intl.formatMessage({ id: "reset_changes" })
    });

    return mapped;
  }

  getDeviceAddress(device) {
    if (_.has(device, "identifiers")) {
      return device.identifiers[0];
    }

    return device.id;
  }

  isSubmitDisabled() {
    return !this.state.selectedLogicalDeviceId;
  }

  handleSubmit = () => {
    const destinationDevice = _.find(this.props.destinationDevices, {
      logicalDeviceId: this.state.selectedLogicalDeviceId,
    });

    return this.props.modal.resolve({
      device: {
        name: destinationDevice.name,
        profile: destinationDevice.profile,
        identifiers: destinationDevice.identifiers,
        logicalDeviceId: this.state.selectedLogicalDeviceId,
      },
      id: this.newAddress,
    });
  };
}

MergeDeviceDialog.propTypes = {
  deviceToMerge: PropTypes.object,
  destinationDevices: PropTypes.array
};

export default injectIntl(MergeDeviceDialog);
