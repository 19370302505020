import { InfectedDeviceServerData } from "@sportal/api";

import { RootState } from "../../root.reducer";
import { getDevices } from "../../root.selectors";

export const getInfectedDevices = (state: RootState) =>
  getDevices(state).infected;

export const getInfectionsMap = (state: RootState): InfectedDeviceServerData =>
  state.devices.infected.list;
