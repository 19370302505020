import {
  HttpService,
  InfectedDevicesProvider,
  InfectedDevicesService,
} from "@sportal/api";

export const makeInfectedDevicesService = (
  http: HttpService,
  baseUrl: string
): InfectedDevicesService =>
  new InfectedDevicesService(new InfectedDevicesProvider(http, baseUrl));
